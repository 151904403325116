import {
  Home,
  Causes,
  About,
  PageNotFound,
  CauseDetail,
  CauseDonation,
  Blogs,
  BlogDetail,
  Contact,
  DonationLayout,
  OneTimeDonation,
  MonthlyDonation,
  ZakatDonation,
  QurbaniDonation,
  Search,
  Gallery,
} from '../pages/website';
import CookiesPolicy from '../pages/website/CookiesPolicy';
import Donation from '../pages/website/Donation';
import PrivacyPolicy from '../pages/website/PrivacyPolicy';
import TermOfUse from '../pages/website/TermOfUse';

const WebsiteRoutes = [
  { path: '/', Component: Home },
  { path: '/causes', Component: Causes },
  { path: '/causes/:pageNo', Component: Causes },
  { path: '/about', Component: About },
  { path: '/causes/details/:causeID', Component: CauseDetail },
  { path: '/causes/donation/:causeID', Component: CauseDonation },
  { path: '/policies', Component: PrivacyPolicy },
  { path: '/cookies-policies', Component: CookiesPolicy },
  { path: '/term-of-use', Component: TermOfUse },
  {
    path: '/donations',
    Component: DonationLayout,
    children: [
      { childPath: '/donations/oneTime', ChildComponent: OneTimeDonation },
      { childPath: '/donations/monthly', ChildComponent: MonthlyDonation },
      { childPath: '/donations/zakat', ChildComponent: ZakatDonation },
      { childPath: '/donations/qurbani', ChildComponent: QurbaniDonation },
    ],
  },
  {
    path: '/donation',
    Component: DonationLayout,
    children: [
      { childPath: '/donation', ChildComponent: OneTimeDonation },
      { childPath: '/donation/oneTime', ChildComponent: OneTimeDonation },
      { childPath: '/donation/monthly', ChildComponent: MonthlyDonation },
      { childPath: '/donation/zakat', ChildComponent: ZakatDonation },
      { childPath: '/donation/qurbani', ChildComponent: QurbaniDonation },
    ],
  },
  // {
  //   path: "/donation",
  //   Component: DonationLayout,
  //   children:[],
  //   // Component: Donation

  //   // children: [
  //   //   { childPath: "/", ChildComponent: OneTimeDonation },
  //   //   { childPath: "/donation/monthly", ChildComponent: MonthlyDonation },
  //   //   { childPath: "/donation/zakat", ChildComponent: ZakatDonation },
  //   //   { childPath: "/donation/qurbani", ChildComponent: QurbaniDonation },
  //   // ],
  // },
  // {
  //   path: "/donation",
  //   Component: OneTimeDonation,
  // },
  { path: '/blogs', Component: Blogs },
  { path: '/blogs/:pageNo', Component: Blogs },
  { path: '/blogs/details/:blogID', Component: BlogDetail },
  { path: '/search/:searchBy/:id', Component: Search },
  { path: '/contact', Component: Contact },
  { path: '/gallery', Component: Gallery },
  { path: '*', Component: PageNotFound },
];

export { WebsiteRoutes };
