import React from 'react';
import { Card, CardBody } from 'reactstrap';
import { Link } from 'react-router-dom';
import { themeConfig } from '../../../../config/theme.config';
import { getAssetUrl } from '../../../../utils/constants';

const DonationType = ({ donationTypesData }) => {
  return (
    <div className="my-5 py-3 pt-md-5">
      <div className="container">
        <h2 className="text-center fw-bold mb-3 mb-lg-5 mb-xl-5 mb-xxl-5">
          {donationTypesData?.title || 'Donation Types'}
        </h2>
        <div className="row py-4 overflow-hidden">
          {donationTypesData?.tiles &&
            Array.isArray(donationTypesData?.tiles) &&
            donationTypesData?.tiles.map((item, index) => {
              return (
                <div className="col-lg-6 col-xl-6 col-12 mb-0">
                  <div className="card3">
                    <span> </span>
                    <div className="d-flex flex-column flex-sm-row flex-md-row flex-lg-row justify-content-start align-items-center gap-5 px-4 py-4 h-100">
                      <div className="circleborder">
                        <div
                          className="circlepic"
                          style={{
                            backgroundColor: item?.color || '#32A7E2',
                          }}
                        >
                          <img
                            src={getAssetUrl(item?.icon)}
                            className="icon-shadow "
                            alt={item?.title}
                            style={{ maxHeight: '95px', maxWidth: '95px' }}
                          />
                        </div>
                      </div>
                      {/* <div
                        className="d-none d-sm-block d-md-block"
                        style={{
                          height: "100px",
                          width: "100px",
                        }}
                      >
                        <div
                          className="d-flex align-items-center justify-content-center"
                          style={{
                            height: "100px",
                            width: "100px",
                            backgroundColor: item?.color || "#32A7E2",
                            borderRadius: "50%",
                          }}
                        >
                          <img
                            src={getAssetUrl(item?.icon)}
                            className="icon-shadow "
                            alt={item?.title}
                            style={{ maxHeight: "95px", maxWidth: "95px" }}
                          />
                        </div>
                      </div> */}
                      <div className="d-flex flex-column justify-content-start align-items-start flex-1">
                        <h4 className="fw-bold fs-2 sm-text-center">
                          {item?.title}
                        </h4>
                        <p
                          className=" tri-div-p"
                          // style={{ fontWeight: 600, color: "grey" }}
                        >
                          {item?.description}
                        </p>
                        <div className="d-flex justify-content-end w-100">
                          <Link
                            to={item?.button}
                            className="text-uppercase bg-main fw-bold px-5 py-2 tri-card-p text-white mx-auto mx-sm-auto mx-md-0 mx-lg-0 mx-xl-0 btn-main"
                            style={{
                              borderRadius: '50px',
                              borderWidth: '2px',
                              fontSize: '12px',
                              textDecoration: 'none',
                            }}
                          >
                            <span className="icon-shadow">Donate Now</span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          {/* <div className="col-lg-6  col-xl-6 col-12 mb-4">
          <Card
            className="rounded-4 h-100"
            style={{ border: '1px dashed gray' }}
          >
            <div className="d-flex flex-column flex-sm-row flex-md-row flex-lg-row justify-content-start align-items-center gap-5 px-4 py-4 h-100">
              <div
                className="px-4 py-3 d-none d-sm-block d-md-block"
                style={{ backgroundColor: '#32A7E2', borderRadius: '50%' }}
              >
                <i className="fas fa-dollar donation-type-icon text-white icon-shadow"></i>
              </div>
              <div className="d-flex flex-column justify-content-start align-items-start flex-1">
                <h4 className="fw-bold fs-2">One Time Donation</h4>
                <p className=" tri-card-p" style={{ fontWeight: 600 }}>
                  One time donation via Ummah Relief can save lives.
                </p>
                <div className="d-flex justify-content-end w-100">
                  <Link
                    to="/donations/oneTime"
                    className="text-uppercase bg-main fw-bold px-5 py-2 tri-card-p text-white mx-auto mx-sm-auto mx-md-0 mx-lg-0 mx-xl-0 btn-main"
                    style={{
                      borderRadius: '50px',
                      borderWidth: '2px',
                      fontSize: '12px',
                      textDecoration: 'none',
                    }}
                  >
                    <span className="icon-shadow">Donate Now</span>
                  </Link>
                </div>
              </div>
            </div>
          </Card>
        </div>
        <div className="col-lg-6 col-xl-6 col-12 mb-4">
          <Card
            className="rounded-4 h-100"
            style={{ border: '1px dashed gray' }}
          >
            <div className="d-flex flex-column flex-sm-row flex-md-row flex-lg-row justify-content-start align-items-center gap-5 px-4 py-4 h-100">
              <div
                className="px-4 py-3 d-none d-sm-block d-md-block"
                style={{ backgroundColor: '#FF8700', borderRadius: '50%' }}
              >
                <i className="fas fa-dollar donation-type-icon text-white icon-shadow"></i>
              </div>
              <div className="d-flex flex-column justify-content-start align-items-start flex-1">
                <h4 className="fw-bold fs-2">Monthly Donation</h4>
                <p className=" tri-card-p" style={{ fontWeight: 600 }}>
                  Donate monthly via Ummah Relief and help feed the hungry.
                </p>
                <div className="d-flex justify-content-end w-100">
                  <Link
                    to="/donations/monthly"
                    className="text-uppercase bg-main fw-bold px-5 py-2 tri-card-p text-white mx-auto mx-sm-auto mx-md-0 mx-lg-0 mx-xl-0 btn-main"
                    style={{
                      borderRadius: '50px',
                      borderWidth: '2px',
                      fontSize: '12px',
                      textDecoration: 'none',
                    }}
                  >
                    <span className="icon-shadow">Donate Now</span>
                  </Link>
                </div>
              </div>
            </div>
          </Card>
        </div>
        <div className="col-lg-6 col-xl-6 col-12 mb-4">
          <Card
            className="rounded-4 h-100"
            style={{ border: '1px dashed gray' }}
          >
            <div className="d-flex flex-column flex-sm-row flex-md-row flex-lg-row justify-content-start align-items-center gap-5 px-4 py-4 h-100">
              <div
                className="px-4 py-3 d-none d-sm-block d-md-block"
                style={{ backgroundColor: '#B548C6', borderRadius: '50%' }}
              >
                <i className="fas fa-dollar donation-type-icon text-white icon-shadow"></i>
              </div>
              <div className="d-flex flex-column justify-content-start align-items-start flex-1">
                <h4 className="fw-bold fs-2">Qurbani</h4>
                <p className=" tri-card-p" style={{ fontWeight: 600 }}>
                  Give and get included with our Qurbani Online Program this
                  year
                </p>
                <div className="d-flex justify-content-end w-100">
                  <Link
                    to="/donations/qurbani"
                    className="text-uppercase bg-main fw-bold px-5 py-2 tri-card-p text-white mx-auto mx-sm-auto mx-md-0 mx-lg-0 mx-xl-0 btn-main"
                    style={{
                      borderRadius: '50px',
                      borderWidth: '2px',
                      fontSize: '12px',
                      textDecoration: 'none',
                    }}
                  >
                    <span className="icon-shadow">Donate Now</span>
                  </Link>
                </div>
              </div>
            </div>
          </Card>
        </div>
        <div className="col-lg-6 col-xl-6 col-12 mb-4">
          <Card
            className="rounded-4 h-100 "
            style={{ border: '1px dashed gray' }}
          >
            <div className="d-flex flex-column flex-sm-row flex-md-row flex-lg-row justify-content-start align-items-center gap-5 px-4 py-4 h-100">
              <div
                className="px-4 py-3 d-none d-sm-block d-md-block"
                style={{ backgroundColor: '#DC3434', borderRadius: '50%' }}
              >
                <i className="fas fa-dollar donation-type-icon text-white icon-shadow"></i>
              </div>
              <div className="d-flex flex-column justify-content-start align-items-start flex-1">
                <h4 className="fw-bold fs-2">Zakat</h4>
                <p className=" tri-card-p" style={{ fontWeight: 600 }}>
                  Help those in desperate need by paying Zakat
                </p>
                <div className="d-flex justify-content-end w-100">
                  <Link
                    to="/donations/zakat"
                    className="text-uppercase bg-main fw-bold px-5 py-2 tri-card-p text-white mx-auto mx-sm-auto mx-md-0 mx-lg-0 mx-xl-0 btn-main"
                    style={{
                      borderRadius: '50px',
                      borderWidth: '2px',
                      fontSize: '12px',
                      textDecoration: 'none',
                    }}
                  >
                    <span className="icon-shadow">Donate Now</span>
                  </Link>
                </div>
              </div>
            </div>
          </Card>
        </div> */}
        </div>
      </div>
    </div>
  );
};

export default DonationType;
