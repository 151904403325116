import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import CausesService from '../../services/causes.service';
import CreditCardForm from './components/CreditCardForm';
import DonationService from '../../services/donation.service';
import EmployeeMatchingGiftProgram from './components/EmployeeMatchingGiftProgram';
import ErrorFormat from '../../utils/ErrorFormatting';
import { PageHeader } from '../../components';
import PersonalInfoForm from './components/PersonalInfoForm';
import Swal from 'sweetalert2';
import ACHPaymentForm from './components/ACHPaymentForm';
import EcheckRadioButtons from '../../components/EcheckRadioButtons';
import DeclineInfo from './components/DonationComponent/DeclineInfo';
import PlaidLink from '../../components/CreatePlaidLink';

const CauseDonation = () => {
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [cause, setCause] = useState({});
  const [creditCardInfo, setCreditCardInfo] = useState({});
  const [personalInfo, setPersonalInfo] = useState({});
  const [amountError, setAmountError] = useState('');
  const [finalDonationData, setFinalDonationData] = useState({});
  const [currency] = useState('usd');
  const [disableSubmitButton, setDisableSubmitButton] = useState(true);
  const [isResetForm, setIsResetForm] = useState(false);
  const [amount, setAmount] = useState(location.state?.amount || 0);
  const [isShowOnDescription, setIsShowOnDescription] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [employeeMatchingGiftProgram, setEmployeeMatchingGiftProgram] =
    useState({});
  const [submissionState, setSubmissionState] = useState(0);

  const [isEcheck, setIsEcheck] = useState(false);
  const [achInfo, setAchInfo] = useState({});
  const [errors, setErrors] = useState({});
  const [stripeToken, setStripeToken] = useState(null);

  const handleStripeTokenReceived = (stripeToken) => {
    setStripeToken(stripeToken);
    // console.log({ stripeToken });
  };
  const extendSubmissionState = () => {
    setSubmissionState((state) => state + 1);
  };

  const scrollTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    const firstNameField = document.getElementById('first_name');
    if (firstNameField) {
      firstNameField.focus();
    }
    scrollTop();
  }, []);

  const getCauseDetails = () => {
    CausesService.getSingleCause(params.causeID).then((res) => {
      // console.log(res);
      setCause(res);
    });
  };

  useEffect(() => {
    document.title = 'Cause Donation | Ummah Relief';
    getCauseDetails();
  }, [params]);

  // console.log("cause testing ",JSON.stringify(cause))

  const breadcrumbItems = [
    {
      title: 'Home',
      route: '/',
      isLast: false,
    },
    {
      title: 'Causes',
      route: '/causes/1',
      isLast: false,
    },
    {
      title: 'Donations',
      route: '/donations/oneTime',
      isLast: false,
    },
    {
      title: cause.title,
      route: '/',
      isLast: true,
    },
  ];

  useEffect(() => {
    const totalForms = 1;
    if (submissionState >= totalForms + 1) {
      if (isEcheck) {
        if (stripeToken) {
          handleSubmit();
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'You did not connect a bank account!',
            allowOutsideClick: false,
            allowEscapeKey: false,
          });
        }
      } else {
        // console.log('subm3', submissionState);
        handleSubmit();
      }
    }
  }, [submissionState]);

  const startSubmit = () => {
    setSubmissionState(1);
    setTimeout(() => {
      setSubmissionState(0);
    }, 1000);
  };

  const handleSubmit = (e) => {
    // e.preventDefault();
    if (amountError !== '') return;
    setIsLoading(true);
    if (finalDonationData.cause_donation.amount == 0) {
      setIsLoading(false);
      // alert("Please select at least one donation");
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Please Donate some amount!',
        allowOutsideClick: false,
        allowEscapeKey: false,
        /* color: '#716add', */
      });
    } else {
      DonationService.submitDonation({
        matchingGift: employeeMatchingGiftProgram,
        personal: personalInfo,
        card: creditCardInfo,
        ach: { stripeToken },
        isEcheck,
        donation_of: {
          oneTime: false,
          monthly: false,
          zakat_fitra_donation: false,
          qurbani: false,
          cause: true,
          special: false,
        },
        payment_through: isEcheck ? 'ach' : 'card',
        cause_donation: {
          cause_id: params.causeID,
          // cause_title: location.state.cause.cause_title,
          cause_title: cause.cause_title,
          amount: amount,
          is_show_on_description: isShowOnDescription,
        },
        currency: currency,
      })
        .then((data) => {
          if (
            data.payment.status &&
            data.email.status &&
            data.donor.status &&
            data.cc_details.status &&
            data.cause_donation.status
          ) {
            Swal.fire({
              icon: 'success',
              title: 'Thanks for helping!',
              text: 'A confirmation email has been sent to the email provided!',
              allowOutsideClick: false,
              allowEscapeKey: false,
            }).then((r) => {
              setTimeout(() => {
                navigate(`/causes/details/${params.causeID}`);
              }, 2000);
            });
            // .then((r) => window.location.reload());
          } else {
            // setErrors(data.payment.message)
            Swal.fire({
              icon: 'error',
              title: 'Error occurred',
              html: ErrorFormat.validationErrors(data.payment.message),
              allowOutsideClick: false,
              allowEscapeKey: false,
            });
          }
        })
        .catch((err) => {
          setIsLoading(false);
          // console.log(err.response.data.message)
          if (err.response.data.message) {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: err.response.data.message,
              customClass: {
                title: 'swal-title',
                closeButton: 'btn-gray',
                confirmButton: 'btn-green',
                cancelButton: 'btn-orang',
              },
              allowOutsideClick: false,
              allowEscapeKey: false,
            });
          }
        });
    }
  };

  useEffect(() => {
    setFinalDonationData({
      matchingGift: employeeMatchingGiftProgram,
      personal: personalInfo,
      card: creditCardInfo,
      ach: { stripeToken },
      isEcheck,
      donation_of: {
        oneTime: false,
        monthly: false,
        zakat_fitra_donation: false,
        qurbani: false,
        cause: true,
        special: false,
      },
      payment_through: isEcheck ? 'ach' : 'card',
      cause_donation: {
        cause_id: params.causeID,
        // cause_title: location.state.cause.cause_title,
        cause_title: cause.cause_title,
        amount: amount,
        is_show_on_description: isShowOnDescription,
      },
      currency: currency,
    });
    // console.log(isValidated(personalInfo), isValidated(creditCardInfo), isValidated(finalDonationData.cause_donation))
    if (
      isValidated(personalInfo) &&
      (isValidated(creditCardInfo) || isValidated(achInfo)) &&
      isValidated(finalDonationData.cause_donation)
    ) {
      setDisableSubmitButton(false);
    } else {
      setDisableSubmitButton(true);
    }
  }, [
    creditCardInfo,
    achInfo,
    personalInfo,
    isShowOnDescription,
    amount,
    employeeMatchingGiftProgram,
  ]);

  const isValidated = (values) => {
    if (typeof values === 'object') {
      return Object.values(values).every(
        (x) => x !== null || x !== '' || x !== 0
      );
    } else {
      return values !== '' || values !== null;
    }
  };

  function wait(timeout) {
    return new Promise((resolve) => {
      setTimeout(resolve, timeout);
    });
  }

  const resetForm = async () => {
    setIsResetForm(true);
    setAmount(0);
    setPersonalInfo({});
    setCreditCardInfo({});
    setAchInfo({});
    setFinalDonationData({});
    setIsEcheck(false);
    await wait(1);
    setIsResetForm(false);
  };

  return (
    <>
      <PageHeader breadcrumbItems={breadcrumbItems} title={cause.title} />
      <div className="container py-5">
        <div className="pb-2">
          <h3 className="text-center text-green text-capitalize fw-bold mb-4">
            {cause.title} Donation
          </h3>
        </div>
        <form onSubmit={(e) => handleSubmit(e)}>
          <div className="row col-12">
            <div className="col-md-6 pt-5 pt-lg-0 pt-xl-0 pt-xxl-0">
              {!isResetForm && (
                <PersonalInfoForm
                  submissionState={submissionState}
                  extendSubmissionState={extendSubmissionState}
                  personalInfo={personalInfo}
                  setPersonalInfo={(info) => setPersonalInfo(info)}
                />
              )}
            </div>
            <div className="col-md-6">
              <h4 className="pt-5 pt-lg-0 pt-xl-0 pt-xxl-0 fw-bold">
                Donation Information
              </h4>
              <hr />
              <div className="form-group mb-3">
                <label htmlFor="amount" className="form-label text-dark">
                  Donation Amount <span className="text-danger">*</span>
                </label>
                <div className="input-group">
                  <button className="btn btn-secondary fw-bold" disabled={true}>
                    $
                  </button>
                  <input
                    type="number"
                    name="amount"
                    id="amount"
                    value={amount}
                    onChange={(e) => {
                      e.target.value <= 0
                        ? setAmount(0)
                        : e.target.value <= 999999 && setAmount(e.target.value);
                    }}
                    onBlur={(e) =>
                      e.target.value <= 0
                        ? setAmountError('Field is required')
                        : setAmountError('')
                    }
                    className="form-control"
                    placeholder="Amount *"
                    required={true}
                  />
                </div>
                {amountError !== '' && (
                  <small className="text-danger">{amountError}</small>
                )}
              </div>
              <div className="form-check mb-3">
                <input
                  className="form-check-input"
                  name="is_show_on_description"
                  type="checkbox"
                  onChange={(e) => setIsShowOnDescription(e.target.checked)}
                  defaultChecked={isShowOnDescription}
                  id="is_show_on_description"
                />
                <label
                  className="form-check-label text-dark"
                  htmlFor="is_show_on_description"
                >
                  Do you want to display it on website cause description?
                </label>
              </div>
              {!isResetForm && (
                <EmployeeMatchingGiftProgram
                  setEmployeeMatchingGiftProgram={(info) =>
                    setEmployeeMatchingGiftProgram(info)
                  }
                />
              )}
              <EcheckRadioButtons
                isEcheck={isEcheck}
                setIsEcheck={(echeck) => setIsEcheck(echeck)}
              />
              {!isResetForm && !isEcheck && (
                <CreditCardForm
                  submissionState={submissionState}
                  extendSubmissionState={extendSubmissionState}
                  creditCardInfo={creditCardInfo}
                  setCreditCardInfo={(creditCard) =>
                    setCreditCardInfo(creditCard)
                  }
                />
              )}
              {!isResetForm && isEcheck && (
                <PlaidLink onStripeTokenReceived={handleStripeTokenReceived} />
                // <ACHPaymentForm
                //   submissionState={submissionState}
                //   extendSubmissionState={extendSubmissionState}
                //   achInfo={achInfo}
                //   setAchInfo={(ach) => setAchInfo(ach)}
                //   errors={errors}
                // />
              )}
              <div className="d-flex justify-content-start flex-wrap align-items-center gap-3">
                <button
                  className="text-uppercase btn-green border-0 py-1 px-3" //'text-uppercase btn btn-light text-white bg-primary fw-bold px-4 tri-card-p'
                  style={
                    {
                      // borderRadius: '20px',
                      // borderWidth: '2px',
                      // fontSize: '14px',
                    }
                  }
                  disabled={isLoading || disableSubmitButton || amount < 6}
                  onClick={startSubmit}
                  type="button"
                >
                  {isLoading ? (
                    <>
                      Proccessing{' '}
                      <span
                        className="spinner-border"
                        role="status"
                        style={{
                          height: '14px',
                          width: '14px',
                        }}
                      ></span>
                    </>
                  ) : (
                    'Submit'
                  )}
                </button>
                <button
                  onClick={resetForm}
                  className="text-uppercase btn-orang border-0 py-1 px-3"
                  style={{
                    borderRadius: '20px',
                    borderWidth: '2px',
                    fontSize: '14px',
                  }}
                  type="button"
                  disabled={isLoading}
                >
                  Reset
                </button>
                <button
                  type="submit"
                  className="text-uppercase btn-gray text-white border-0 py-1 px-3"
                  style={{
                    borderRadius: '20px',
                    borderWidth: '2px',
                    fontSize: '14px',
                  }}
                  // className="btn btn-secondary"
                  // style={{ borderRadius: 30 }}
                  onClick={() =>
                    navigate(`/causes/details/${params.causeID}`, {
                      replace: true,
                    })
                  }
                  disabled={isLoading}
                >
                  Cancel
                </button>
              </div>
              <DeclineInfo type={'Cause'} />
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default CauseDonation;
