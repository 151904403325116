import { useEffect, useState } from 'react';
import HomeService from '../services/home.service';

const useHomeScreenData = () => {
  const [aboutUsData, setAboutUsData] = useState({});
  const [carouselData, setCarouselData] = useState([]);
  const [top3CausesData, setTop3CausesData] = useState([]);
  const [donateByData, setDonateByData] = useState({});
  const [donationTypesData, setDonationTypesData] = useState({});
  const [ourMissionData, setOurMissionData] = useState({});
  const [bannerCauseData, setBannerCauseData] = useState({});
  const [bannerContactData, setBannerContactData] = useState({});
  const [bannerDonationData, setBannerDonationData] = useState({});
  const [loadindHomeData, setLoadingHomeData] = useState(false);

  const getHomeData = async () => {
    setLoadingHomeData(true);
    HomeService.getHomeData()
      .then((res) => {
        // console.log('Home Data => ', res);
        setAboutUsData(res?.about_us || {});
        setCarouselData(res?.carousel || []);
        setTop3CausesData(res?.causes || []);
        setDonateByData(res?.donate_by || {});
        setDonationTypesData(res?.donation_types || {});
        setOurMissionData(res?.our_mission || {});
        setBannerCauseData(res?.banner_cause);
        setBannerContactData(res?.banner_contact);
        setBannerDonationData(res?.banner_donation);
        setLoadingHomeData(false);
      })
      .catch((error) => {
        console.error(error);
        setLoadingHomeData(false);
      });
  };

  useEffect(() => {
    getHomeData();
  }, []);
  return {
    aboutUsData,
    carouselData,
    top3CausesData,
    donateByData,
    donationTypesData,
    ourMissionData,
    bannerCauseData,
    bannerContactData,
    bannerDonationData,
    loadindHomeData,
  };
};

export default useHomeScreenData;
