import React, { useEffect } from 'react';
import { PageHeader } from '../../components';
import { CookiesPolicyComponent } from './components/PrivacyPolicyComponent';

const breadcrumbItems = [
  {
    title: 'Home',
    route: '/',
    isLast: false,
  },
  {
    title: 'Cookies Policy',
    route: '/',
    isLast: true,
  },
];

const CookiesPolicy = () => {
  useEffect(() => {
    document.title = 'Cookies Policy | Ummah Relief';
  }, []);
  return (
    <>
      <PageHeader breadcrumbItems={breadcrumbItems} title="Cookies Policy" />
      <CookiesPolicyComponent />
    </>
  );
};

export default CookiesPolicy;
