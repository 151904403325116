import React, {useEffect} from 'react';
import {PageHeader} from "../../components";
import {PrivacyPolicies} from "./components/PrivacyPolicyComponent";

const breadcrumbItems = [
    {
        title: 'Home',
        route: '/',
        isLast: false
    },
    {
        title: 'Privacy Policy',
        route: '/',
        isLast: true
    }
]

const PrivacyPolicy = () => {

    useEffect(() => {
        document.title = "Privacy Policy | Ummah Relief"
    }, [])
    return (
        <>
            <PageHeader breadcrumbItems={breadcrumbItems} title="Privacy Policy"/>
            <PrivacyPolicies/>
        </>
    );
};

export default PrivacyPolicy;