import React, { useEffect } from 'react';
import { PageHeader } from '../../components';
import { CauseList } from './components/CausesComponent';
import NoIndexNoFollow from '../../components/NoIndexNoFollow';
import { useLocation } from 'react-router-dom';

const breadcrumbItems = [
  {
    title: 'Home',
    route: '/',
    isLast: false,
  },
  {
    title: 'Causes',
    route: '/',
    isLast: true,
  },
];
const Causes = () => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, [location.pathname]);
  useEffect(() => {
    document.title = 'Causes | Ummah Relief';
  }, []);

  return (
    <>
      <NoIndexNoFollow />
      <PageHeader breadcrumbItems={breadcrumbItems} title="Causes" />
      <CauseList />
    </>
  );
};

export default Causes;
