import React, { useEffect } from 'react';

import { MaskedInput } from '../../../../components';
import { MassageBoxImage } from '../../../../utils/Images';

const tdAmountWidth = '100px';
const SpecialRequestForm = ({
  options,
  setOptions = () => {},
  handleSum,
  totalAmount,
  setTotalDonation,
  totalDonation,
}) => {
  const handleRadioClick = (id, title, value) => {
    const dataObj = {
      donation_type_id: id,
      title: title,
      amount: parseInt(value),
      is_special_request: 1,
    };

    var temp = [...options];
    temp.map((item, index) => {
      if (item.id === id) {
        temp[index] = { ...item, amount: '' };
      }
    });
    setOptions([...temp]);

    totalDonation.forEach((item, index) => {
      if (totalDonation && item.donation_type_id === dataObj.donation_type_id) {
        totalDonation.splice(index, 1);
      }
    });
    setTotalDonation((old) => [...old, dataObj]);
  };

  const handInputChange = (id, value, title) => {
    const dataObj = {
      donation_type_id: id,
      title: title,
      amount: value ? parseInt(value) : 0,
      is_special_request: 1,
    };

    var temp = [...options];
    temp.map((item, index) => {
      if (item.id === id) {
        temp[index] = { ...item, amount: parseInt(value) };
      }
    });
    setOptions([...temp]);

    if (parseInt(dataObj.amount) < 1) {
      totalDonation.forEach((item, index) => {
        if (
          totalDonation &&
          item.donation_type_id === dataObj.donation_type_id
        ) {
          totalDonation.splice(index, 1);
        }
      });
      setTotalDonation([...totalDonation]);
    } else {
      totalDonation.forEach((item, index) => {
        if (
          totalDonation &&
          item.donation_type_id === dataObj.donation_type_id
        ) {
          totalDonation.splice(index, 1);
        }
      });
      setTotalDonation((old) => [...old, dataObj]);
    }
  };

  function handleInputFocus(e, id) {
    const option1 = document.getElementById(`spacial_option_1_${id}`);
    const option2 = document.getElementById(`spacial_option_2_${id}`);
    const option3 = document.getElementById(`spacial_option_3_${id}`);
    totalDonation.forEach((item, index) => {
      if (totalDonation && item.donation_type_id === id) {
        totalDonation.splice(index, 1);
      }
    });
    option1.checked = false;
    option2.checked = false;
    //option3.value = '';
  }

  const getSum = () => {
    return totalDonation.reduce(function (sum, current) {
      return sum + parseInt(current.amount ? current.amount : 0);
    }, 0);
  };

  useEffect(() => {
    if (handleSum !== '' && handleSum !== undefined) {
      let total = getSum();
      handleSum(total);
    }
  }, [totalDonation]);

  return (
    <>
      <h4 className="mb-2 d-flex flex-column flex-sm-column flex-md-column flex-lg-row w-full justify-content-between align-items-center gap-3  pb-2 mb-2 fw-bold">
        Special Request
      </h4>
      <div
        className="card border-0 pt-3"
        style={{ backgroundColor: '#adb5bd' }}
      >
        <div className="card-body">
          {options &&
            options.map((item, index) => (
              <div
                key={index}
                className="d-flex flex-column flex-sm-column flex-md-column flex-lg-row w-full justify-content-between align-items-center gap-3 border-bottom pb-2 mb-2"
              >
                <div className="donationTitle px-2">
                  <strong>{item.title}</strong>
                </div>
                <div className="d-flex flex-row  flex-xs-column  donationOptions justify-content-center align-items-center gap-3 px-2">
                  <table className="table table-borderless p-0">
                    <tbody>
                      <tr>
                        <td
                          className="border-0 p-0"
                          style={{
                            width: tdAmountWidth,
                            backgroundColor: 'inherit',
                          }}
                        >
                          <div className="form-check pt-3">
                            <input
                              className="form-check-input"
                              type="radio"
                              name={`spacial_${item.id}`}
                              id={`spacial_option_1_${item.id}`}
                              defaultChecked={false}
                              onChange={(e) =>
                                handleRadioClick(
                                  item.id,
                                  item.title,
                                  item.option1
                                )
                              }
                            />
                            <label
                              className="form-check-label text-dark"
                              htmlFor={`spacial_option_1_${item.id}`}
                            >
                              <span className="px-1">$</span>
                              {item.option1}
                            </label>
                          </div>
                        </td>
                        <td
                          className="border-0 p-0"
                          style={{
                            width: tdAmountWidth,
                            backgroundColor: 'inherit',
                          }}
                        >
                          <div className="form-check pt-3">
                            <input
                              className="form-check-input"
                              type="radio"
                              name={`spacial_${item.id}`}
                              id={`spacial_option_2_${item.id}`}
                              defaultChecked={false}
                              onChange={(e) =>
                                handleRadioClick(
                                  item.id,
                                  item.title,
                                  item.option2
                                )
                              }
                            />
                            <label
                              className="form-check-label text-dark"
                              htmlFor={`spacial_option_2_${item.id}`}
                            >
                              <span className="px-1">$</span>
                              {item.option2}
                            </label>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="form-group d-flex justify-content-end align-items-center pt-2">
                    <label
                      htmlFor={`spacial_option_3_${item.id}`}
                      className="form-label text-dark pt-2 mx-1 text-no-wrap"
                      style={{ width: '100%', textAlign: 'left' }}
                    >
                      Other $
                    </label>
                    
                      {/* console.log(item) */}
                    
                    <MaskedInput
                      id={`spacial_option_3_${item.id}`}
                      placeholder="0"
                      value={item.amount}
                      mask="999999"
                      name="other_amount"
                      min={0}
                      max={19}
                      onChange={(e) =>
                        handInputChange(item.id, e.target.value, item.title)
                      }
                      onFocus={(e) => handleInputFocus(e, item.id)}
                      onBlur={(e) => {
                        //  FormValidator.handleOnBlur(e, setErrors);
                        e.target.value !== ''
                          ? handInputChange(item.id, e.target.value, item.title)
                          : handInputChange(item.id, item.amount, item.title);
                      }}
                    />
                    {/* <input
                      type="number"
                      className="form-control"
                      placeholder="0"
                      id={`spacial_option_3_${item.id}`}
                      min="0"
                      onFocus={(e) => handleInputFocus(e, item.id)}
                      onChange={(e) => {
                        handInputChange(item.id, e, item.title);
                      }} */}
                    {/* // value={item.id == totalDonation.donation_type_id &&  totalDonation. }
                      style={{ width: "70%", textAlign: "right" }}
                    /> */}
                    {/* <small className="text-danger" style={{backroundImage: `url(${MassageBoxImage})`}}>
                      value not be negative
                    </small> */}
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </>
  );
};

export default SpecialRequestForm;
