import {
  Collapse,
  Nav,
  NavItem,
  NavLink,
  Navbar,
  NavbarBrand,
  NavbarToggler,
} from 'reactstrap';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import React, { useContext, useEffect, useRef, useState } from 'react';

import WebService from '../services/web.service';
import {
  FacebookLogo,
  InstagramLogo,
  SkypeLogo,
  WebsiteLogo,
  XLogo,
  YoutubeLogo,
} from '../utils/Images';
import useScreen from '../hooks/useScreen';
import SocialLinks from './SocialLinks';
import { AppContext } from '../layouts/WebsiteLayout';

const useFocus = () => {
  const htmlElRef = useRef(null);
  const setFocus = () => {
    htmlElRef.current && htmlElRef.current.focus();
  };

  return [htmlElRef, setFocus];
};

const WebsiteHeader = (props) => {
  const { isMenuOverly } = useScreen();
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const [isOpen, setIsOpen] = useState(false);
  const [isMenuFixed, setIsMenuFixed] = useState(false);
  const [searchInputShow, setSearchInputShow] = useState(false);
  const [keywords, setKeywords] = useState('');
  const [inputRef, setInputFocus] = useFocus();
  const [searchIconShow, setSearchIconShow] = useState(true);
  const [logo, setLogo] = useState({});
  const [sitePhone, setSitePhone] = useState('');
  const [siteEmail, setSiteEmail] = useState('');
  const { websiteSettings } = useContext(AppContext);

  const toggleTopFixed = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setIsMenuFixed(true);
    } else if (scrolled <= 300) {
      setIsMenuFixed(false);
    }
  };

  window.addEventListener('scroll', toggleTopFixed, { passive: true });

  document.addEventListener('keydown', function (event) {
    if (event.key === 'Escape') {
      setSearchInputShow(false);
    }
  });

  const toggle = () => {
    setIsOpen(!isOpen);
    setInputFocus();
    let toogleIcon = document.getElementById('nav-toggle-custom');
    // toogleIcon.classList.toggle('nav-toggle-active');
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    setSearchInputShow(false);
    setKeywords('');
  }, [location.pathname]);

  const checkWindowWidth = () => {
    // console.log("check window with");
    if (window.innerWidth < 768) {
      setSearchIconShow(false);
    } else {
      setSearchIconShow(true);
    }
  };

  useEffect(() => {
    checkWindowWidth();
  }, [window.innerWidth]);

  const getWebSettings = (res) => {
    setLogo(res.web_logo);
    const webInfo = res.web_info;
    for (let i = 0; i < webInfo.length; i++) {
      // console.log(webInfo)
      if (webInfo[i].setting === 'uan') {
        setSitePhone(
          webInfo[i].value.replace(
            /([0-9]{1})([0-9]{3})([0-9]{3})([0-9]+)/,
            '$1-$2-$3-$4'
          )
        );
      }
      if (webInfo[i].setting === 'emailAddress') {
        setSiteEmail(webInfo[i].value);
      }
    }
  };
  //bg-main navbar navbar-expand-md sticky-true
  useEffect(() => {
    websiteSettings && getWebSettings(websiteSettings);
  }, [websiteSettings]);

  window.addEventListener('scroll', () => {
    isOpen && setIsOpen(false);
  });

  return (
    <div>
      <div className="py-2 border-bottom bg-main">
        <div className="container mt-1 mb-1 d-flex justify-content-sm-between align-items-center justify-content-center flex-sm-row flex-column">
          <div className="top-bar-text sm_text-center text-white">
            {/* Call:  */}
            <span>
              <i className="fas fa-phone"></i>{' '}
              <a
                className="text-decoration-none text-white"
                href={`tel:${sitePhone || '1-800-713-4482'}`}
              >
                {sitePhone || '1-800-713-4482'}
              </a>{' '}
            </span>
            | {/* <br className="sm-display-block" /> */}
            {/*  Email: */}
            <span>
              <i className="fas fa-envelope"></i>{' '}
              <a
                className="text-decoration-none text-white"
                href={`mailto:${siteEmail || 'Ummah@ummahrelief.org'}`}
              >
                {siteEmail || 'Ummah@ummahrelief.org'}
              </a>
            </span>
          </div>
          <SocialLinks />
        </div>
      </div>
      <div className="d-flex justify-content-center py-4">
        <NavbarBrand
          className={searchInputShow ? 'z-index-999999' : ''}
          style={{ cursor: 'pointer' }}
          onClick={() => navigate('/')}
        >
          <img
            src={
              logo.logo
                ? `data:image/${logo.logo_ext};base64,${logo.logo}`
                : 'https://ummahrelief.org/admin/public/assets/admin/uploads/logo/logo_light_default.png'
            }
            alt="Ummah Relief Logo"
            className="site-logo"
          />
        </NavbarBrand>
      </div>

      {/*  <ContactInfo sitePhone={sitePhone} siteEmail={siteEmail} /> */}
      {/* {isMenuOverly && <div style={{ height: '80px' }}></div>} */}
      {!isMenuOverly && isMenuFixed && <div style={{ height: '85px' }}></div>}
      <Navbar
        {...props}
        //color="white"
        className="bg-main"
        sticky
        //light
        expand="md"
        container={true}
        //fixed={isMenuFixed || isMenuOverly ? 'top' : ''}
        fixed={isMenuFixed ? 'top' : ''}
      >
        {/* {!isMenuFixed && isMenuOverly && (
          <ContactInfo sitePhone={sitePhone} siteEmail={siteEmail} />
        )} */}

        {/* <NavbarBrand
          className={searchInputShow ? 'z-index-999999' : ''}
          style={{ cursor: 'pointer' }}
          onClick={() => navigate('/')}
        >
          <img
            src={
              logo.logo
                ? `data:image/${logo.logo_ext};base64,${logo.logo}`
                : 'https://ummahrelief.org/admin/public/assets/admin/uploads/logo/logo_light_default.png'
            }
            alt="Ummah Relief Logo"
            className="site-logo"
          />
        </NavbarBrand> */}
        <NavbarToggler
          onClick={toggle}
          id="nav-toggle-custom"
          className={isOpen ? 'nav-toggle-active' : ''}
        >
          <i className="fa fa-bars text-white fw-bold fs-3"></i>
        </NavbarToggler>
        <Collapse isOpen={isOpen} navbar style={{ justifyContent: 'center' }}>
          <Nav className="mr-auto" navbar /* hidden={searchInputShow} */>
            {!searchInputShow && (
              <NavItem
                className={`nav-item-hover ${
                  location.pathname === '/' && 'bg-dark-main'
                }`}
              >
                <Link
                  to="/"
                  className={`py-3 px-3 h-100 px-xl-3 px-xxl-4 d-flex align-items-center text-uppercase text-white text-decoration-none nav-menu-link text-center ${
                    location.pathname === '/' && ' '
                  }`}
                >
                  <i className="fa fa-home icon-shadow"></i>
                </Link>
              </NavItem>
            )}
            {!searchInputShow && (
              <NavItem
                className={` nav-item-hover ${
                  location.pathname === '/about' && 'bg-dark-main'
                }`}
              >
                <Link
                  to="/about"
                  className={`px-2 px-lg-3 h-100 px-xl-3 px-xxl-4 d-flex align-items-center text-uppercase text-white text-decoration-none nav-menu-link text-center ${
                    location.pathname === '/about' && ' '
                  }`}
                >
                  <span className="icon-shadow">About </span>
                </Link>
              </NavItem>
            )}
            {!searchInputShow && (
              <NavItem
                className={` nav-item-hover ${
                  location.pathname ===
                    `/causes/${
                      params && params.pageNo
                        ? params.pageNo
                        : 'details/' + params.causeID
                    }` && 'bg-dark-main'
                }`}
              >
                <Link
                  to="/causes/1"
                  className={`px-2 px-lg-3 h-100 px-xl-3 px-xxl-4 d-flex align-items-center text-uppercase text-white text-decoration-none nav-menu-link text-center ${
                    location.pathname ===
                      `/causes/${
                        params && params.pageNo
                          ? params.pageNo
                          : 'details/' + params.causeID
                      }` && ' '
                  }`}
                >
                  <span className="icon-shadow"> Causes </span>
                </Link>
              </NavItem>
            )}
            {!searchInputShow && (
              <NavItem
                className={` nav-item-hover ${
                  (location.pathname === '/donations/oneTime' ||
                    location.pathname === '/donations/monthly' ||
                    location.pathname === '/donations/zakat' ||
                    location.pathname === '/donations/qurbani') &&
                  'bg-dark-main'
                }`}
              >
                <Link
                  to="/donations/oneTime"
                  className={`px-2 px-lg-3 h-100 px-xl-3 px-xxl-4 d-flex align-items-center text-uppercase text-white text-decoration-none nav-menu-link text-center ${
                    (location.pathname === '/donations/oneTime' ||
                      location.pathname === '/donations/monthly' ||
                      location.pathname === '/donations/zakat' ||
                      location.pathname === '/donations/qurbani') &&
                    ' '
                  }`}
                >
                  <span className="icon-shadow"> Donation </span>
                </Link>
              </NavItem>
            )}
            {!searchInputShow && (
              <NavItem
                className={` nav-item-hover ${
                  location.pathname ===
                    `/blogs/${
                      params && params.pageNo
                        ? params.pageNo
                        : 'details/' + params.blogID
                    }` && 'bg-dark-main'
                }`}
              >
                <Link
                  to="/blogs/1"
                  className={`px-2 px-lg-3 h-100 px-xl-3 px-xxl-4 d-flex align-items-center text-uppercase text-white text-decoration-none nav-menu-link text-center ${
                    location.pathname ===
                      `/blogs/${
                        params && params.pageNo
                          ? params.pageNo
                          : 'details/' + params.blogID
                      }` && 'fw-bold bg-dark-main'
                  }`}
                >
                  <span className="icon-shadow"> Blog </span>
                </Link>
              </NavItem>
            )}
            {/*<NavItem className="mx-3 d-flex align-items-center">*/}
            {/*    <Link to="/gallery"*/}
            {/*          className={`text-uppercase text-dark text-decoration-none ${location.pathname === '/gallery' && 'fw-bold'}`}>*/}
            {/*        Gallery*/}
            {/*    </Link>*/}
            {/*</NavItem>*/}

            {!searchInputShow && (
              <NavItem
                className={` nav-item-hover ${
                  location.pathname === '/contact' && 'bg-dark-main'
                }`}
              >
                <Link
                  to="/contact"
                  className={`px-2 px-lg-3 h-100 px-xl-3 px-xxl-4 d-flex align-items-center text-uppercase text-white text-decoration-none nav-menu-link text-center ${
                    location.pathname === '/contact' && ' '
                  }`}
                >
                  <span className="icon-shadow"> Contact </span>
                </Link>
              </NavItem>
            )}

            {!searchInputShow && (
              <NavItem className=" nav-item-hover">
                <NavLink
                  onClick={() => setSearchInputShow(!searchInputShow)}
                  className={`px-2 px-lg-3 h-100 px-xl-3 px-xxl-4 d-flex align-items-center text-uppercase text-white text-decoration-none nav-menu-link text-center ${
                    location.pathname ===
                      `/blogs/${
                        params && params.pageNo
                          ? params.pageNo
                          : 'details/' + params.blogID
                      }` && 'fw-bold bg-dark-main'
                  }`}
                >
                  <i className="fas fa-search fw-bold icon-shadow"></i>
                </NavLink>
              </NavItem>
            )}
            {searchInputShow && (
              <NavItem>
                <form
                  onSubmit={() => navigate(`/search/keywords/${keywords}`)}
                  className="w-100 h-75 py-1 px-0 mx-auto "
                >
                  <div className="input-group" style={{ borderRadius: 30 }}>
                    <input
                      type="text"
                      className="form-control p-1 px-4"
                      value={keywords}
                      ref={inputRef}
                      onChange={(e) => setKeywords(e.target.value)}
                      style={{
                        borderTopLeftRadius: 30,
                        borderBottomLeftRadius: 30,
                      }}
                      autoFocus={true}
                      placeholder="Search keyword for cause or blog"
                    />
                    <button
                      className="btn btn-success"
                      disabled={keywords === ''}
                      type="button"
                      onClick={() => navigate(`/search/keywords/${keywords}`)}
                      style={{ borderRadius: 0 }}
                    >
                      <i className="fas fa-search"></i>
                    </button>
                    <button
                      className="btn btn-secondary"
                      type="button"
                      onClick={() => {
                        setSearchInputShow(false);
                        setKeywords('');
                      }}
                      style={{
                        borderTopRightRadius: 30,
                        borderBottomRightRadius: 30,
                      }}
                    >
                      <i className="fas fa-close"></i>
                    </button>
                  </div>
                </form>
              </NavItem>
            )}
          </Nav>
        </Collapse>
        {false && (
          <div
            className={`container position-absolute w-full bg-white p-3`}
            style={{
              zIndex: 9999,
              display: searchInputShow ? 'block' : 'none',
            }}
          >
            <form onSubmit={() => navigate(`/search/keywords/${keywords}`)}>
              <div
                className="input-group search-bar"
                style={{ borderRadius: 30 }}
              >
                <input
                  type="text"
                  className="form-control p-2 px-4"
                  value={keywords}
                  ref={inputRef}
                  onChange={(e) => setKeywords(e.target.value)}
                  style={{
                    borderTopLeftRadius: 30,
                    borderBottomLeftRadius: 30,
                  }}
                  autoFocus={true}
                  placeholder="Search keyword for cause or blog"
                />
                <button
                  className="btn btn-success"
                  disabled={keywords === ''}
                  type="submit"
                  // onClick={() => navigate(`/search/keywords/${keywords}`)}
                  style={{ borderRadius: 0 }}
                >
                  <i className="fas fa-search"></i>
                </button>
                <button
                  className="btn btn-outline-secondary"
                  type="button"
                  onClick={() => {
                    setSearchInputShow(false);
                    setKeywords('');
                  }}
                  style={{
                    borderTopRightRadius: 30,
                    borderBottomRightRadius: 30,
                  }}
                >
                  <i className="fas fa-close"></i>
                </button>
              </div>
            </form>
          </div>
        )}
      </Navbar>
    </div>
  );
};

export default WebsiteHeader;

const ContactInfo = ({ sitePhone, siteEmail }) => {
  return (
    <div className="py-2 border-bottom w-100">
      <div className="container">
        <div className="fw-bold top-bar-text sm_text-center">
          Call:{' '}
          <a
            className="text-decoration-none text-black"
            href={`tel:${sitePhone || '1-800-713-4482'}`}
          >
            {sitePhone || '1-800-713-4482'}
          </a>{' '}
          | <br className="sm-display-block" /> Email:{' '}
          <a
            className="text-decoration-none text-black"
            href={`mailto:${siteEmail || 'Ummah@ummahrelief.org'}`}
          >
            {siteEmail || 'Ummah@ummahrelief.org'}
          </a>
        </div>
      </div>
    </div>
  );
};
