import API from "../utils/api";

const addSubscription = (email) => {
    return API.post('/subscribe_api', {email: email})
        .then(response => {
            return response.data
        })
}

const SubscriptionService = {
    addSubscription
}

export default SubscriptionService