import axios from 'axios';
import API from '../utils/api';

const getHomeData = () => {
  return API.get('/home').then(
    (response) => {
      return response.data;
    }
  );
};

const HomeService = {
  getHomeData,
};

export default HomeService;
