import React, { useContext, useEffect, useState } from 'react';
import WebService from '../../../../services/web.service';
import { AppContext } from '../../../../layouts/WebsiteLayout';

const ContactMap = () => {
  const [googleMap, setGoogleMap] = useState();
  const { websiteSettings } = useContext(AppContext);

  const getWebSettings = (res) => {
    const webInfo = res.web_info;
    for (let i = 0; i < webInfo.length; i++) {
      if (webInfo[i].setting === 'googleMap') {
        setGoogleMap(webInfo[i].value);
      }
    }
  };

  useEffect(() => {
    websiteSettings && getWebSettings(websiteSettings);
  }, [websiteSettings]);
  // console.log('google mape api response', googleMap);
  return (
    <section className="contact-map-area">
      <div className="container-fluid pl-0 pr-0">
        <div className="row no-gutters">
          <div className="col-xl-12">
            <div
              className="map-area map-02  wow fadeInUp2  animated"
              data-wow-delay=".1s"
              style={{
                visibility: 'visible',
                animationDelay: '0.1s',
                animationName: 'fadeInUp2',
              }}
            >
              {
                <iframe
                  src={
                    googleMap ||
                    'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2961.9620880265593!2d-88.282915!3d42.065432!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x6bc5f7e0580c19a2!2sUmmah%20Relief%20International!5e0!3m2!1sen!2s!4v1664290788405!5m2!1sen!2s'
                  }
                  width="600"
                  height="450"
                  style={{ border: 0 }}
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
              }
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactMap;
