import { useCallback, useEffect, useState } from "react";
import { usePlaidLink } from "react-plaid-link";
import PaymentService from "../services/payment.service";
import Swal from "sweetalert2";
function PlaidLink(props) {
  const [token, setToken] = useState("");
  const [loading, setLoading] = useState(true); // Add loading state
  const { achPayments, stripeUid, onStripeTokenReceived } = props;

  async function createLinkToken() {
    // PaymentService.createLinkToken()
    //   .then((data) => {
    //     if (data.link_token) {
    //       console.log({ data });
    //       setToken(data.link_token);
    //     }
    //   })
    //   .catch((err) => {
    //     // setIsLoading(false);
    //     if (err.response.data.message) {
    //       // Swal.fire({
    //       //   icon: "error",
    //       //   title: "Oops...",
    //       //   text: err.response.data.message,
    //       // });
    //     }
    //   });
    const fetchConfig = {
      method: "POST",
    };
    const response = await fetch(
      `${process.env.REACT_APP_HOST_API_KEY}stripe-plaid/link-token`,
      fetchConfig
    );
    const jsonResponse = await response.json();
    const { link_token } = jsonResponse;
    // console.log({ link_token });
    setToken(link_token);
    setLoading(false); // Set loading to false after token retrieval
  }

  const onSuccess = useCallback(
    (publicToken, metadata) => {
      const { account_id } = metadata;

      // Exchange a public token for an access one.
      async function exchangeTokens() {
        const fetchConfig = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            public_token: publicToken,
            account_id,
          }),
        };
        const response = await fetch(
          `${process.env.REACT_APP_HOST_API_KEY}stripe-plaid/stripe-token`,
          fetchConfig
        );
        const jsonResponse = await response.json();
        // console.log("Exchange token response:", jsonResponse);
        onStripeTokenReceived(jsonResponse.stripe_token);
      }

      exchangeTokens();
    },
    [stripeUid, onStripeTokenReceived]
  );

  const { open, ready } = usePlaidLink({
    token,
    onSuccess,
  });

  // get link_token from your server when component mounts
  useEffect(() => {
    createLinkToken();
  }, []);

  useEffect(() => {
    if (achPayments && ready) {
      open();
    }
  }, [achPayments, ready, open]);

  return (
    <div style={{ marginTop: "20px", marginBottom: "20px" }}>
      {loading ? (
        <div>Loading...</div> // Show loader while loading
      ) : (
        <button
          className="text-uppercase btn-green border-0 py-1 px-3"
          style={{
            borderRadius: "20px",
            borderWidth: "2px",
            fontSize: "14px",
          }}
          onClick={() => open()}
          disabled={!ready}
          type="button"
        >
          Connect a bank account
        </button> // Show button after loading
      )}
    </div>
  );
}

export default PlaidLink;
