import React, { useContext, useEffect, useState } from 'react';
import WebService from '../services/web.service';
import {
  FacebookLogo,
  InstagramLogo,
  SkypeLogo,
  XLogo,
  YoutubeLogo,
} from '../utils/Images';
import { AppContext } from '../layouts/WebsiteLayout';

function SocialLinks({ size = 20 }) {
  const [facebook, setFacebook] = useState({});
  const [youtube, setYoutube] = useState({});
  const [twitter, setTwitter] = useState({});
  const [skype, setSkype] = useState({});
  const [instagram, setInstagram] = useState({});
  const [linkedin, setLinkedIn] = useState({});
  const { websiteSettings } = useContext(AppContext);

  const getWebSettings = (websiteSettings) => {
    const social = websiteSettings.web_social;
    for (let i = 0; i < social.length; i++) {
      if (social[i].setting === 'socialFacebook') {
        setFacebook(social[i]);
      }
      if (social[i].setting === 'socialTwitter') {
        setTwitter(social[i]);
      }
      if (social[i].setting === 'socialLinkedin') {
        setLinkedIn(social[i]);
      }
      if (social[i].setting === 'socialYoutube') {
        setYoutube(social[i]);
      }
      if (social[i].setting === 'socialSkype') {
        setSkype(social[i]);
      }
      if (social[i].setting === 'socialInstagram') {
        setInstagram(social[i]);
      }
    }
  };

  useEffect(() => {
    websiteSettings && getWebSettings(websiteSettings);
  }, [websiteSettings]);
  return (
    <div className="d-flex align-items-center gap-2">
      <a className="link-dark" href={facebook.value} target="_blank">
        <img src={FacebookLogo} alt="" width={size} />
      </a>
      <a className="link-dark" href={skype.value} target="_blank">
        <img src={SkypeLogo} alt="" width={size} />
      </a>
      <a className="link-dark" href={twitter.value} target="_blank">
        <img src={XLogo} alt="" width={size} />
      </a>
      <a className="link-dark" href={instagram.value} target="_blank">
        <img src={InstagramLogo} alt="" width={size} />
      </a>
      <a className="link-dark" href={youtube.value} target="_blank">
        <img src={YoutubeLogo} alt="" width={size} />
      </a>
    </div>
  );
}

export default SocialLinks;
