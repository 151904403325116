import React from "react";
import { Card, CardBody } from "reactstrap";
import { themeConfig } from "../../../../config/theme.config";
import {
  HandHoldingDollar,
  Zakat,
  Home,
  WaterTap,
  HelpingHand,
  MoneyBag,
} from "../../../../utils/Images";
import { getAssetUrl } from "../../../../utils/constants";

const OurMission = ({ ourMissionData }) => {
  return (
    <div className="my-5 py-3 pt-md-5">
      <div className="container my-2">
        <h2 className="text-center fw-bold mb-3 mb-lg-4 mb-xl-4 mb-xxl-4">
          {ourMissionData?.title || "Our Mission"}
        </h2>
        <div className="row py-5">
          {ourMissionData?.tiles &&
            Array.isArray(ourMissionData?.tiles) &&
            ourMissionData?.tiles.map((item, index) => {
              return (
                <div className="col-lg-4 col-md-6 col-12 py-3 px-3">
                  <div
                    className="card h-100 p-2 box-shadow border-0"
                    // style={{ borderRadius: "10px" }}
                  >
                    <div className="d-flex justify-content-center">
                      {/* <div
                        className="p-2 text-white"
                        style={{
                          backgroundColor: themeConfig.orange,
                          borderRadius: "16px",
                        }}
                      > */}
                      <span className="mission mt-3">
                        <img
                          className="icon-shadow"
                          src={getAssetUrl(item?.icon)}
                          alt=""
                          width={40}
                        />
                      </span>
                      {/* </div> */}
                    </div>
                    <div className="my-3">
                      <h5 className="fs-4 text-center text-main fw-bold">
                        {item?.title}
                      </h5>
                      <p className="card-text text-center">
                        {item?.description}
                      </p>
                    </div>
                  </div>
                </div>
              );
            })}
          {/* <div className="col-lg-4 col-md-6 col-12 py-2 px-2">
            <div
              className="h-100 p-2 box-shadow"
              style={{ borderRadius: '10px' }}
            >
              <div className="d-flex justify-content-center">
                <div
                  className="p-2 text-white"
                  style={{
                    backgroundColor: themeConfig.orange,
                    borderRadius: '16px',
                  }}
                >
                  <img
                    className="icon-shadow"
                    src={HandHoldingDollar}
                    alt=""
                    width={40}
                  />
                </div>
              </div>
              <div className="my-3">
                <h5 className="fs-4 text-center text-main fw-bold">
                  Charity for Education
                </h5>
                <p className="card-text text-center">
                  We provide a number of services and supplies such as books,
                  school supplies, meals and student’s preventive health care
                  services.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-12 py-2 px-2">
            <div
              className="h-100 p-2 box-shadow"
              style={{ borderRadius: '10px' }}
            >
              <div className="d-flex justify-content-center">
                <div
                  className="p-2 text-white"
                  style={{
                    backgroundColor: themeConfig.orange,
                    borderRadius: '16px',
                  }}
                >
                  <img className="icon-shadow" src={Zakat} alt="" width={40} />
                </div>
              </div>
              <div className="my-3">
                <h5 className="fs-4 text-center text-main fw-bold">
                  Food for Hungry Children
                </h5>
                <p className="card-text text-center">
                  Feed the Children exists to end childhood hunger. Around the
                  world, we provide nourishing meals every day to thousands of
                  children.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-12 py-2 px-2">
            <div
              className="h-100 p-2 box-shadow"
              style={{ borderRadius: '10px' }}
            >
              <div className="d-flex justify-content-center">
                <div
                  className="p-2 text-white"
                  style={{
                    backgroundColor: themeConfig.orange,
                    borderRadius: '16px',
                  }}
                >
                  <img className="icon-shadow" src={Home} alt="" width={40} />
                </div>
              </div>
              <div className="my-3">
                <h5 className="fs-4 text-center text-main fw-bold">
                  Tiny Homes for the Homeless
                </h5>
                <p className="card-text text-center">
                  The project is part of a national movement of tiny-house
                  villages, an alternative approach to housing the homeless.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-12 py-2 px-2">
            <div
              className="h-100 p-2 box-shadow"
              style={{ borderRadius: '10px' }}
            >
              <div className="d-flex justify-content-center">
                <div
                  className="p-2 text-white"
                  style={{
                    backgroundColor: themeConfig.orange,
                    borderRadius: '16px',
                  }}
                >
                  <img
                    className="icon-shadow"
                    src={WaterTap}
                    alt=""
                    width={40}
                  />
                </div>
              </div>
              <div className="my-3">
                <h5 className="fs-4 text-center text-main fw-bold">
                  Bringing Clean Water
                </h5>
                <p className="card-text text-center">
                  Providing a reliable and safe water source will unlock
                  potential by returning time for study, work, and imagination.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-12 py-2 px-2">
            <div
              className="h-100 p-2 box-shadow"
              style={{ borderRadius: '10px' }}
            >
              <div className="d-flex justify-content-center">
                <div
                  className="p-2 text-white"
                  style={{
                    backgroundColor: themeConfig.orange,
                    borderRadius: '16px',
                  }}
                >
                  <img
                    className="icon-shadow"
                    src={HelpingHand}
                    alt=""
                    width={40}
                  />
                </div>
              </div>
              <div className="my-3">
                <h5 className="fs-4 text-center text-main fw-bold">
                  Help Little Hands
                </h5>
                <p className="card-text text-center">
                  We believe that every child is special. With our help,
                  regardless of their unique needs or challenges, each child
                  possesses the ability to achieve greatness.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-12 py-2 px-2">
            <div
              className="h-100 p-2 box-shadow"
              style={{ borderRadius: '10px' }}
            >
              <div className="d-flex justify-content-center">
                <div
                  className="p-2 text-white"
                  style={{
                    backgroundColor: themeConfig.orange,
                    borderRadius: '16px',
                  }}
                >
                  <img
                    className="icon-shadow"
                    src={MoneyBag}
                    alt=""
                    width={40}
                  />
                </div>
              </div>
              <div className="my-3">
                <h5 className="fs-4 text-center text-main fw-bold">
                  Donate for the Children
                </h5>
                <p className="card-text text-center">
                  To transform communities through helping children, youth and
                  their families who need support.
                </p>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default OurMission;
