import API from '../utils/api';

const donationType = () => {
  return API.get('/donation/types').then((response) => {
    return response.data;
  });
};

const getOneTimeDonationTypes = () => {
  return API.get('/one_time_donation_types').then((response) => {
    return response.data;
  });
};

const getMonthlyDonationTypes = () => {
  return API.get('/monthly_donation_types').then((response) => {
    return response.data;
  });
};

const getSpecialDonationTypes = () => {
  return API.get('/special_donation_types').then((response) => {
    return response.data;
  });
};

const getQurbaniOptions = () => {
  return API.get('/qurbanies').then((response) => {
    return response.data;
  });
};

const getZakatNissab = () => {
  return API.get('/zakat_nissab').then((response) => {
    return response.data;
  });
};

const getFitraNissab = () => {
  return API.get('/fitra_nissab').then((response) => {
    return response.data;
  });
};

const submitDonation = (data) => {
  return API.post('/payments', data).then((response) => {
    return response.data;
  });
};

const DonationService = {
  donationType,
  getQurbaniOptions,
  getZakatNissab,
  getFitraNissab,
  submitDonation,
  getOneTimeDonationTypes,
  getMonthlyDonationTypes,
  getSpecialDonationTypes,
};

export default DonationService;
