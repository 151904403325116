import { useState, createContext, useEffect } from 'react';
import * as React from 'react';
import { WebsiteFooter, WebsiteHeader } from '../components';
import { Outlet } from 'react-router-dom';
import { FaArrowCircleUp } from 'react-icons/fa';
import { Button } from './Styles';
import WebService from '../services/web.service';
import NoIndexNoFollow from '../components/NoIndexNoFollow';

export const AppContext = createContext();

const WebsiteLayout = () => {
  const [visible, setVisible] = useState(false);
  const [websiteSettings, setWebSiteSetting] = useState(null);

  const getWebSettings = () => {
    WebService.webSetting().then((res) => {
      setWebSiteSetting(res);
    });
  };

  useEffect(() => {
    getWebSettings();
  }, []);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 200) {
      setVisible(true);
    } else if (scrolled <= 200) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  window.addEventListener('scroll', toggleVisible);

  return (
    <AppContext.Provider value={{ websiteSettings }}>
      {`${window.location.origin}`.includes('dev.') && <NoIndexNoFollow />}
      <WebsiteHeader />
      <Outlet />
      <WebsiteFooter />
      <Button>
        <FaArrowCircleUp
          onClick={scrollToTop}
          style={{ display: visible ? 'inline' : 'none' }}
        />
      </Button>
    </AppContext.Provider>
  );
};

export default WebsiteLayout;
