import React, { useEffect, useState } from 'react';
import Carousel from 'react-material-ui-carousel';
import { Backdrop, Box, Modal } from '@mui/material';
import { useKeenSlider } from 'keen-slider/react';
//import 'keen-slider/keen-slider.min.css';

const MediaSlider = ({ media = [] }) => {
  const blogImagesAndVideosArray = media || [];
  const animation = { duration: 5000, easing: (t) => t };
  const [open, setOpen] = React.useState(false);
  const [modalData, setModatData] = useState(null);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const [sliderRef, instanceRef] = useKeenSlider(
    {
      loop: true,
      mode: 'snap',
      rtl: false,
      slides: { perView: 'auto', spacing: 10 },
      initial: 0,
      slideChanged(slider) {
        setCurrentSlide(slider.track.details.rel);
      },
      created() {
        setLoaded(true);
      },

      /*  created(s) {
        s.moveToIdx(0.5, true, animation);
      },
      updated(s) {
        s.moveToIdx(s.track.details.abs + 1, true, animation);
      },
      animationEnded(s) {
        s.moveToIdx(s.track.details.abs + 1, true, animation);
      }, */
    },
    [
      (slider) => {
        let timeout;
        let mouseOver = false;
        function clearNextTimeout() {
          clearTimeout(timeout);
        }
        function nextTimeout() {
          clearTimeout(timeout);
          if (mouseOver) return;
          timeout = setTimeout(() => {
            slider.next();
          }, 2000);
        }
        slider.on('created', () => {
          slider.container.addEventListener('mouseover', () => {
            mouseOver = true;
            clearNextTimeout();
          });
          slider.container.addEventListener('mouseout', () => {
            mouseOver = false;
            nextTimeout();
          });
          nextTimeout();
        });
        slider.on('dragStarted', clearNextTimeout);
        slider.on('animationEnded', nextTimeout);
        slider.on('updated', nextTimeout);
      },
    ]
  );

  return (
    <div>
      <div className="navigation-wrapper d-flex">
        {blogImagesAndVideosArray.length > 0 &&
          loaded &&
          instanceRef.current && (
            <div
              className="d-flex flex-column align-items-center justify-content-center"
              style={{ marginRight: '6px' }}
            >
              <Arrow
                left
                onClick={(e) =>
                  e.stopPropagation() || instanceRef.current?.prev()
                }
                disabled={currentSlide === 0}
              />
            </div>
          )}
        <div ref={sliderRef} className="keen-slider ">
          {blogImagesAndVideosArray.length > 0 &&
            blogImagesAndVideosArray.map((item, index) => {
              if (item.type === 'image') {
                return (
                  <div
                    key={index}
                    className="keen-slider__slide number-slide3 flex-1"
                    onClick={() => {
                      setModatData({ index });
                      handleOpen();
                    }}
                    style={{
                      height: '200px',
                      cursor: 'pointer',
                      minWidth: 'max-content',
                    }}
                  >
                    <img
                      style={{
                        height: '200px',
                        cursor: 'pointer',
                      }}
                      src={item?.thumbnail}
                    />
                  </div>
                );
              }
              if (item.type === 'video') {
                return (
                  <div
                    key={index}
                    className="keen-slider__slide number-slide3"
                    style={{ minWidth: 'max-content' }}
                    onClick={() => {
                      setModatData({ index });
                      handleOpen();
                    }}
                  >
                    <img
                      style={{
                        height: '200px',
                        cursor: 'pointer',
                      }}
                      src={item?.thumbnail}
                    />
                    {/* <video
                           
                            style={{
                              height: '200px',
                              cursor: 'pointer',
                            }}
                            className="video-player w-100"
                            controls={false}
                          >
                            <source src={item.src} type="video/mp4" />
                            <source src={item.src} type="video/ogg" />
                            Your browser does not support the video tag.
                          </video> */}
                  </div>
                );
              }
              return null;
            })}
        </div>
        {blogImagesAndVideosArray.length > 0 &&
          loaded &&
          instanceRef.current && (
            <div
              className="d-flex flex-column align-items-center justify-content-center"
              style={{ marginLeft: '6px' }}
            >
              <Arrow
                onClick={(e) =>
                  e.stopPropagation() || instanceRef.current?.next()
                }
                disabled={
                  currentSlide ===
                  instanceRef.current.track.details.slides.length - 1
                }
              />
            </div>
          )}
      </div>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={() => {}}
        slots={{ backdrop: Backdrop }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '100%',
            bgcolor: 'none',
            boxSizing: 'unset',
            height: '100vh',
            p: 4,
          }}
        >
          <div className="h-100">
            <div className="w-100 h-100">
              <div className="d-flex justify-content-end px-3">
                <div
                  style={{
                    cursor: 'pointer',
                    color: 'white',
                    fontSize: '20px',
                  }}
                  onClick={handleClose}
                >
                  <i className="fas fa-close" />
                </div>
              </div>
              <div className="h-100 d-flex align-items-center">
                <div className="w-100">
                  <Carousel
                    index={modalData?.index}
                    autoPlay={true}
                    stopAutoPlayOnHover={true}
                    indicators={true}
                    fullHeightHover={true}
                    animation={'slide'}
                    navButtonsAlwaysVisible={true}
                    changeOnFirstRender={true}
                  >
                    {Array.isArray(blogImagesAndVideosArray) &&
                      blogImagesAndVideosArray.map((item, i) => (
                        <Slide index={i} item={item} />
                      ))}
                  </Carousel>
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default MediaSlider;

const Slide = ({ item = {}, index = 0, height = 600 }) => {
  if (item.type === 'image') {
    return (
      <div className="d-flex justify-content-center px-5 zoom-img blog-carosul-content-height">
        <img className="px-5" key={index} src={item.src} />
      </div>
    );
  }
  return (
    <div className="px-5 blog-carosul-content-height">
      <video
        key={index}
        className="video-player w-100 px-5 h-100"
        autoPlay={true}
        controls
      >
        <source src={item.src} type="video/mp4" />
        <source src={item.src} type="video/ogg" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

function Arrow(props) {
  const disabeld = props.disabled ? ' arrow--disabled' : '';
  return (
    <svg
      onClick={props.onClick}
      style={{
        height: '18px',
        cursor: 'pointer',
        /* color: props.disabled ? 'gray' : 'black',
        fill: props.disabled ? 'gray' : 'black',
        cursor: props.disabled ? 'not-allowed' : 'pointer', */
      }}
      className={`arrow ${props.left ? 'arrow--left' : 'arrow--right'}`}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      {props.left && (
        <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />
      )}
      {!props.left && (
        <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
      )}
    </svg>
  );
}
