import API from "../utils/api";

const getBlogs = (pageNo) => {
    return API.get(`/blogs?page=${pageNo}`)
        .then((response) => {
            return response.data;
        });
};

const getSingleBlog = (blogID) => {
    return API.get(`/blogs/${blogID}`)
        .then((response) => {
            return response.data;
        });
}

const getCategories = () => {
    return API.get(`/categories`)
        .then((response) => {
            // console.log(response)
            return response.data;
        });
}

const getCBlogsByQuery = (searchType, data) => {
    return API.post(`getBlogsByQuery/${searchType}`, data)
        .then(response => {
            return response.data
        })
}


const BlogService = {
    getBlogs,
    getSingleBlog,
    getCategories,
    getCBlogsByQuery
};

export default BlogService;