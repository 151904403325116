import API from "../utils/api";

const submitQuery = (data) => {
    return API.post('/message', data)
        .then(response => {
            return response.data
        })
}

const ContactService = {
    submitQuery
}

export default ContactService