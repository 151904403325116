import { isValidDateValue } from '@testing-library/user-event/dist/utils';

export const nameRegex = /^([a-zA-Z ]{0,})$/;
export const zipCodeRegex = /^([a-zA-Z0-9 -]{0,15})$/;

export const wait = (timeout) => {
  return new Promise((resolve) => {
    setTimeout(resolve, timeout);
  });
};

export const getAssetUrl = (target) =>
  `${process.env.REACT_APP_SERVER_PUBLIC_URL}/${target}`;
