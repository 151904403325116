import React, { useEffect } from 'react';
import { PageHeader } from '../../components';
import { TermOfUseComponent } from './components/PrivacyPolicyComponent';

const breadcrumbItems = [
  {
    title: 'Home',
    route: '/',
    isLast: false,
  },
  {
    title: 'Terms of Use',
    route: '/',
    isLast: true,
  },
];

const TermOfUse = () => {
  useEffect(() => {
    document.title = 'Terms of Use | Ummah Relief';
  }, []);
  return (
    <>
      <PageHeader breadcrumbItems={breadcrumbItems} title="Terms of Use" />
      <TermOfUseComponent />
    </>
  );
};

export default TermOfUse;
