import React, { useEffect, useState } from 'react';

import LinesEllipsis from 'react-lines-ellipsis';
import { Link } from 'react-router-dom';

const CauseCard = ({ cause }) => {
  const [progressBar, setProgressBar] = useState(0);

  useEffect(() => {
    let percentage = cause.raised / cause.goal;
    if (percentage) {
      setProgressBar(percentage * 100);
    } else {
      setProgressBar(0);
    }
  }, [cause]);

  function formatNum(str) {
    //remove the + sign if you want a string instead
    return +str.replace(/\./g, '').replace(/,/g, '.');
  }

  return (
    <div className="col-sm-12 col-md-6 col-lg-4 col-xxl-3 col-xl-3 p-2">
      <div className="causes-content border h-100 d-flex flex-column">
        <div className="causes-thumb blogs">
          <Link
            to={`/causes/details/${cause.id}`}
            className="text-decoration-none"
          >
            {/*<img src={`data:image/png;base64, ${cause.image_base64}`} alt="causes"/>*/}
            <div className="blogs__thumb--img">
              <img
                // src={`${process.env.REACT_APP_SERVER_PUBLIC_URL}/admin/uploads/cause/${cause.image}`}
                src={`${cause.image}`}
                alt="causes"
                style={{
                  width: '100%',
                  minHeight: '250px',
                  maxHeight: '250px',
                }}
              />
            </div>
          </Link>
          <Link
            to={`/causes/details/${cause.id}`}
            // className="donate-btn text-decoration-none fw-bold tri-card-p"
            // style={{ borderRadius: 30 }}
            className="donate-btn text-uppercase btn btn-light fw-bold px-4 tri-card-p"
            style={{
              borderRadius: '20px',
              borderWidth: '2px',
              fontSize: '12px',
            }}
          >
            Donate Now
            <i className="fas fa-plus" />
          </Link>
        </div>
        <div className="causes-details border h-100 d-flex flex-column justify-content-between">
          <div>
            <Link
              to={`/causes/details/${cause.id}`}
              className="text-decoration-none"
            >
              <h4 className="cause-title">
                <Link
                  to={`/causes/details/${cause.id}`}
                  className="text-decoration-none "
                >
                  <div className="md-cause-f fw-bold">{cause.title}</div>
                  {/* <LinesEllipsis
                    text={cause.title}
                    maxLine="3"
                    ellipsis="..."
                    trimRight
                    basedOn="letters"
                    className="md-cause-f fw-bold"
                  /> */}
                </Link>
              </h4>
            </Link>
            <p className="tri-card-p md-hidden ">
              <LinesEllipsis
                text={cause.short_desc}
                maxLine="2"
                ellipsis="..."
                trimRight
                basedOn="letters"
                className=""
              />
            </p>
          </div>
          <>
            {/* <div className='donation-box h-100'>
            <div>
              {cause.goal ? (
                <p className='tri-card-p '>
                  <i className='fa-solid fa-chart-simple'></i>
                  <strong>Goal:</strong> $
                  {cause.goal ? parseFloat(cause.goal).toFixed(0) : 0}
                </p>
              ) : (
                <></>
              )}
            </div>
            <div>
              <p className='tri-card-p '>
                <i className='fa-solid fa-thumbs-up'></i>
                <strong>Raised:</strong> $
                {cause.raised ? parseFloat(cause.raised).toFixed(0) : 0}
              </p>
            </div>
          </div> */}
            {/* {cause.goal ? (
            <div
              className={` ${
                progressBar !== 0 ? `progress  mb-3` : 'display-hidden mb-2'
              }`}
            >
              <div
                className='progress-bar'
                role='progressbar'
                style={{ width: `${Math.ceil(progressBar)}%` }}
                aria-valuenow={Math.ceil(progressBar)}
                aria-valuemin={0}
                aria-valuemax={100}
              >
                <span
                  className='wow cssanimation fadeInLeft'
                  style={{
                    visibility: 'visible',
                    animationName: 'bfadeInLeft',
                  }}
                >
                  {Math.ceil(progressBar) > 100 ? 100 : Math.ceil(progressBar)}%
                </span>
              </div>
            </div>
          ) : (
            <></>
          )} */}
          </>
          <div>
            {/*   <Link className="read-more tri-card-p h-100">Read More</Link> */}
            <div className="blogs__content px-0">
              <Link
                className="more_btn_02 text-decoration-none tri-card-p"
                to={`/causes/details/${cause.id}`}
              >
                Read more <i className="fas fa-arrow-right" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CauseCard;
