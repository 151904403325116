import React from 'react';
import Icons from "../utils/Icons";

function isMobileOrTablet() {
    return /(android|iphone|ipad|mobile)/i.test(navigator.userAgent);
}

const SocialMediaShare = ({platform, size = 25}) => {
        const url = window.location.href
        const icons = {
            facebook: {
                url: `https://www.facebook.com/sharer/sharer.php?u=${url}`,
                icon: <Icons.FacebookIcon size={size}/>
            },
            twitter: {
                url: `https://www.twitter.com/share?url=${url}`,
                icon: <Icons.TwitterIcon size={size}/>
            },
            whatsapp: {
                url: `https://web.whatsapp.com/send?text=${url}`,
                icon: <Icons.WhatsAppIcon size={size}/>
            },
            messenger: {
                url: `https://www.facebook.com/dialog/send?link=${url}&redirect_uri=${url}`,
                icon: <Icons.MessengerIcon size={size}/>
            }
        }

        const handleShare = async (url) => {
            const left = (window.screen.width);
            const top = (window.screen.height);
            const windowFeatures = "status=no,height=" + 300 + ",width=" + 300 + ",resizable=yes,left=" + left + ",top=" + top + ",screenX=" + left - 200 + ",screenY=" + top - 50 + ",toolbar=no,menubar=no,scrollbars=no,location=no,directories=no";
            window.open(url, 'new', windowFeatures);
            return false;
            // try {
            //     await navigator.share(shareData);
            // } catch (err) {
            //     console.log(`Error: ${err}`)
            // }
            // console.log(url)
        }

        return (
            <button
                aria-label="facebook"
                className="share-button"
                onClick={() => handleShare(icons[platform].url)}
                style={{
                    backgroundColor: 'transparent',
                    border: 'none',
                    padding: 0,
                    font: 'inherit',
                    color: 'inherit',
                    cursor: 'pointer'
                }}
            >
                {icons[platform].icon}
                {/*<svg viewBox="0 0 64 64" width="32" height="32">*/}
                {/*    <circle cx="32" cy="32" r="31" fill="#3b5998"></circle>*/}
                {/*    <path*/}
                {/*        d="M34.1,47V33.3h4.6l0.7-5.3h-5.3v-3.4c0-1.5,0.4-2.6,2.6-2.6l2.8,0v-4.8c-0.5-0.1-2.2-0.2-4.1-0.2 c-4.1,0-6.9,2.5-6.9,7V28H24v5.3h4.6V47H34.1z"*/}
                {/*        fill="white"></path>*/}
                {/*</svg>*/}
            </button>
        );
    }
;

export default SocialMediaShare;