import React, { useEffect, useState } from 'react';

import FormValidator from '../../../utils/validation';
import { nameRegex } from '../../../utils/constants';

const EmployeeMatchingGiftProgram = ({ setEmployeeMatchingGiftProgram }) => {
  const [errors, setErrors] = useState({});
  const [companyName, setCompanyName] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('debit_credit_card');
  const [comments, setComments] = useState('');
  useEffect(() => {
    /* company_name: companyName,
      payment_method: paymentMethod, */
    const obj = {
      comment_message: comments,
    };
    if (setEmployeeMatchingGiftProgram !== undefined) {
      setEmployeeMatchingGiftProgram({ ...obj });
    }
  }, [companyName, paymentMethod, comments]);

  return (
    <>
      <h4 className="fw-bold">Employee Matching Gift Program</h4>
      <hr />
      <p className="tri-card-p">
        Ummah Relief is a registered charity in "Donation Matching Program" with
        many organizations. Please provide your company name and address in the
        comments section if your company offers "Employee Matching Gift
        Program".
      </p>
      {/* <div className='form-group mb-3'>
        <input
          type='text'
          name='company_name'
          id='company_name'
          onChange={(e) => {
            nameRegex.test(e.target.value) &&
              setCompanyName(e.target.value.replace(/^\d+$/, ''));
          }}
          value={companyName}
          className='form-control'
          onBlur={(e) => FormValidator.handleOnBlur(e, setErrors)}
          placeholder='Company Name'
          required={true}
        />
        {errors.companyName !== '' && (
          <small className='text-danger'>{errors.companyName}</small>
        )}
      </div>
      <div className='form-group mb-3'>
        <label htmlFor='payment_method' className='form-label text-dark'>
          Payment Method: <span className='text-danger'>*</span>
        </label>
        <div className='d-flex flex-wrap aling-items-center'>
          <div style={{ marginRight: '1.5rem' }} className='form-check'>
            <input
              className='form-check-input'
              type='radio'
              name='payment_method'
              value='debit_credit_card'
              checked={paymentMethod === 'debit_credit_card'}
              defaultChecked
              onChange={(e) => setPaymentMethod(e.target.value)}
            />
            <label
              className='form-check-label text-dark'
              htmlFor='debit_cradit_card'
            >
              Debit/Credit Card
            </label>
          </div>
          <div className='form-check'>
            <input
              className='form-check-input'
              type='radio'
              name='payment_method'
              value='e_check'
              checked={paymentMethod === 'e_check'}
              onChange={(e) => setPaymentMethod(e.target.value)}
            />
            <label
              className='form-check-label text-dark'
              htmlFor='debit_cradit_card'
            >
              US Bank
            </label>
          </div>
        </div>
        {errors.paymentMethod !== '' && (
          <small className='text-danger'>{errors.paymentMethod}</small>
        )}
      </div> */}
      <div className="form-group mb-3">
        <textarea
          type="text"
          name="comment_message"
          id="comment_message"
          rows={4}
          onChange={(e) => {
            setComments(e.target.value.replace(/^\d+$/, ''));
          }}
          value={comments}
          className="form-control"
          placeholder="Comments / Message"
        />
      </div>
    </>
  );
};

export default EmployeeMatchingGiftProgram;
