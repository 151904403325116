import { Card, CardBody, CardTitle } from 'reactstrap';
import React, { useEffect, useState } from 'react';

import DonationService from '../../../../services/donation.service';
import { MaskedInput } from '../../../../components';
import { themeConfig } from '../../../../config/theme.config';

const QurbaniForm = ({
  qurbaniDonation,
  setQurbaniDonation,
  setTotalAmount,
  totalAmount,
}) => {
  const [associatedCountries, setAssociatedCountries] = useState([]);
  const [qurbani, setQurbani] = useState([]);

  const getQuarbani = () => {
    DonationService.getQurbaniOptions().then((data) => {
      setAssociatedCountries(data.countries);
      setQurbani(data.options);
    });
  };
  useEffect(() => {
    getQuarbani();
  }, [setQurbani]);

  const handleInput = (
    country_id,
    state_id,
    qurbani_type_id,
    e,
    full_amount,
    shared_amount,
    is_shareable
  ) => {
    if (e.target.value == '' || e.target.value == 0) {
      //   return console.log("qty is empty or equal to zero1");
      const dataObj = {
        is_shared: is_shareable,
        country_id: country_id,
        state_id: state_id ? state_id : 0,
        qurbani_type_id: qurbani_type_id,
        qty: e.target.value,
      };
      qurbaniDonation.forEach((item, index) => {
        if (
          qurbaniDonation &&
          item.country_id === dataObj.country_id &&
          item.state_id === dataObj.state_id &&
          item.qurbani_type_id === dataObj.qurbani_type_id &&
          item.is_shared === dataObj.is_shared
        ) {
          qurbaniDonation.splice(index, 1);
          setQurbaniDonation([...qurbaniDonation]);
        }
      });
    } else {
      //ugger;
      const dataObj = {
        is_shared: is_shareable,
        country_id: country_id,
        state_id: state_id ? state_id : 0,
        qurbani_type_id: qurbani_type_id,
        qty: e.target.value,
        full_amount: full_amount,
        share_amount: shared_amount,
        total:
          parseInt(is_shareable) === 1
            ? parseInt(e.target.value) * parseInt(shared_amount)
            : parseInt(e.target.value) * parseInt(full_amount),
      };
      qurbaniDonation.forEach((item, index) => {
        if (
          qurbaniDonation &&
          item.country_id === dataObj.country_id &&
          item.state_id === dataObj.state_id &&
          item.qurbani_type_id === dataObj.qurbani_type_id &&
          item.is_shared === dataObj.is_shared &&
          item.share_amount === dataObj.share_amount &&
          item.full_amount === dataObj.full_amount
        ) {
          qurbaniDonation.splice(index, 1);
        }
      });
      setQurbaniDonation([...qurbaniDonation, dataObj]);
    }
  };

  // const getSum = () => {
  //     return qurbaniDonation.reduce(function (sum, current) {
  //         let total = 0
  //         if (is_shared) {
  //             total = parseInt(current.amount ? current.amount : 0)
  //         } else {
  //
  //         }
  //         return sum + ;
  //     }, 0);
  // }

  // useEffect(() => {
  //
  //     setTotalAmount()
  // }, [qurbaniDonation])

  // const handleCheckbox = (inputId, checkboxId) => {
  //     const checkbox = document.getElementById(checkboxId)
  //     const input = document.getElementById(inputId)
  //     input.disabled = !checkbox.checked;
  // }

  // function getRandomNumberBetween(min, max) {
  //     return Math.floor(Math.random() * (max - min + 1) + min);
  // }

  return (
    <>
      <h4 className="my-3">Udhiya/Qurbani</h4>
      {/* <p className='text-info fw-bold'>
        For whole enter total shares mentioned in front of each option
      </p> */}
      <div>
        {associatedCountries &&
          associatedCountries.map((country, index) => {
            return (
              <Card className="mt-2 pt-4" key={index}>
                <CardBody className="py-3" id="card-body">
                  <CardTitle tag="h5" style={{ color: themeConfig.green }}>
                    {/* {country.name}  */}
                    {country.state_name ? country.state_name : country.name}
                  </CardTitle>
                  {qurbani.map((options, innerIndex) => {
                    // console.log(country.state_id);
                    return options.country_id == country.country_id &&
                      options.state_id == country.state_id ? (
                      <>
                        <div
                          className={`d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center align-items-start mb-2 py-1 `}
                          key={innerIndex}
                        >
                          <div>
                            <label
                              className="form-label text-dark"
                              style={{
                                fontSize: '110%',
                                color: themeConfig.dark,
                              }}
                            >
                              <span style={{ fontWeight: 600 }}>$</span>
                              {`${
                                parseInt(options.is_shareable) === 1
                                  ? options.share_amount
                                  : options.full_amount
                              }  `}
                              <span style={{ fontWeight: 600 }}> - </span>
                              {`${
                                parseInt(options.is_shareable) === 1
                                  ? 'per share  for'
                                  : 'for whole'
                              } ${options.qurbani_type}`}

                              {/* <small className='text-info fw-bold'>
                                (Shares: {options.no_of_shares})
                              </small> */}
                            </label>
                          </div>
                          <div className="form-group">
                            <MaskedInput
                              id="qty"
                              placeholder="Qty"
                              required={true}
                              mask="999"
                              onChange={(e) => {
                                // console.log('e: ', e.target.value);
                                handleInput(
                                  country.country_id,
                                  country.state_id,
                                  options.qurbani_type_id,
                                  e,
                                  options.full_amount,
                                  options.share_amount,
                                  parseInt(options.is_shareable)
                                );
                              }}
                              name="qty"
                              min={0}
                              max={3}
                              isCreditCardNumber={true}
                              onFo
                            />
                            {/* <input
                            type='number'
                            name='qty'
                            placeholder='Qty'
                            id='qty'
                            min='0'
                            onChange={(e) =>
                              handleInput(
                                country.country_id,
                                country.state_id,
                                options.qurbani_type_id,
                                e,
                                options.full_amount,
                                options.share_amount,
                                options.is_shareable
                              )
                            }
                            className='form-control'
                          /> */}
                          </div>
                        </div>
                        {parseInt(options.is_shareable) === 1 && (
                          <div
                            className={`d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center align-items-start mb-2  py-1`}
                            key={innerIndex}
                          >
                            <div>
                              <label
                                className="form-label text-dark"
                                style={{
                                  fontSize: '110%',
                                  color: themeConfig.dark,
                                }}
                              >
                                <span style={{ fontWeight: 600 }}>$</span>
                                {`${options.full_amount}  `}
                                <span style={{ fontWeight: 600 }}> - </span>
                                {`for whole ${options.qurbani_type}`}

                                {/*  <small className='text-info fw-bold'>
                                  (Shares: 1)
                                </small> */}
                              </label>
                            </div>
                            <div className="form-group">
                              <MaskedInput
                                id="qty"
                                placeholder="Qty"
                                required={true}
                                mask="999"
                                onChange={(e) => {
                                  handleInput(
                                    country.country_id,
                                    country.state_id,
                                    options.qurbani_type_id,
                                    e,
                                    options.full_amount,
                                    options.share_amount,
                                    0
                                  );
                                }}
                                name="qty"
                                min={0}
                                max={3}
                                isCreditCardNumber={true}
                                onFo
                              />
                            </div>
                          </div>
                        )}
                      </>
                    ) : (
                      ''
                    );
                  })}
                </CardBody>
              </Card>
            );
          })}
      </div>
      <div className="d-flex d-none">
        <label className="quarbani-checkbx fs-5 pt-1 pe-3">Total:</label>
        <input
          type="text"
          className="form-control float-left"
          placeholder="$ 0"
          // value={calculatedAmount}
          value="0"
          disabled={true}
          style={{ width: '80px' }}
        />
      </div>
    </>
  );
};

export default QurbaniForm;
