import React, { useState } from 'react';
import { themeConfig } from '../../../../config/theme.config';
import { YouthubeThumbnail } from '../../../../utils/Images';
import YoutubeVideoPlayerModal from '../YoutubeVideoPlayerModal';

const WhoWeAre = ({}) => {
  const [isOpenVideoModal, setIsOpenVideoModal] = useState(false);
  const videoUrl = 'https://www.youtube.com/watch?v=N7WmmhwCDuQ';

  const onImageClick = () => {
    setIsOpenVideoModal(true);
  };
  return (
    <>
      <div className="container my-5">
        <div className="d-flex flex-column  flex-lg-row flex-xl-row justify-content-between align-items-center row">
          <div className=" col-lg-8 col-md-12">
            <h2 className="fw-bold mb-3">Who We Are</h2>
            <p style={{ color: themeConfig.green, fontSize: 22 }}>
              Providing Worldwide Charity Service for Almost 30 Years
            </p>
            <p className="tri-card-p">
              Ummah Relief International is a committed grassroots relief
              organization, devoted to delivering humanitarian aid with swift
              and precise actions, bringing assistance and hope to thousands of
              individuals affected by both natural and man-made disasters.
            </p>
          </div>
          <div className="col-lg-4 col-md-12">
            <div
              className="abut_imgs my-5 cursor-pointer"
              style={{ position: 'relative', cursor: 'pointer' }}
              onClick={onImageClick}
            >
              <img src={YouthubeThumbnail} className="about1 img-fluid" />
              <div
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                }}
                id="play-video"
                className="video-play-button"
              >
                <span></span>
              </div>
            </div>
            {/*  <video className="video-player w-100" controls>
              <source src="uri_interview.mp4" type="video/mp4" />
              <source src="uri_interview.mp4" type="video/ogg" />
              Your browser does not support the video tag.
            </video> */}
          </div>
          <YoutubeVideoPlayerModal
            open={isOpenVideoModal}
            onClose={() => {
              setIsOpenVideoModal(false);
            }}
            url={videoUrl}
          />
        </div>
      </div>
    </>
  );
};

export default WhoWeAre;
