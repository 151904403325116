import { Card } from 'reactstrap';
import React from 'react';

import { getAssetUrl } from '../../../../utils/constants';

const DonationWaysSeparator = ({ donateByData }) => {
  const parse = require('html-react-parser').default;

  return (
    <div className="my-5 py-3 pt-md-5">
      <div className="container">
        <h2 className="text-center fw-bold mb-3 mb-lg-5 mb-xl-5 mb-xxl-5">
          {donateByData?.title || 'Donation Types'}
        </h2>
        <div className="row py-4">
          {donateByData?.tiles &&
            Array.isArray(donateByData?.tiles) &&
            donateByData?.tiles.map((item, index) => {
              return (
                <div className="col-lg-3 col-xl-3 col-sm-6 col-md-6 col-12 py-lg-2 py-md-5 py-sm-5 py-5">
                  <Card
                    className="text-white h-100 donate-by-card"
                    // style={{ backgroundColor: themeConfig.green }}
                  >
                    <div className="py-0">
                      <div className="d-flex flex-column justify-content-start align-items-center gap-3 px-2">
                        <div className="img_pod overflow-hidden">
                          <img
                            src={getAssetUrl(item?.icon)}
                            className="icon-shadow"
                            alt={item?.title}
                            width={100}
                            // height={80}
                          />
                        </div>
                        {/* <div
                          className="px-2 text-white text-center"
                          style={{ width: 200, height: 80 }}
                        >
                          <img
                            src={getAssetUrl(item?.icon)}
                            className="icon-shadow"
                            alt={item?.title}
                            width={150}
                            height={80}
                          />
                        </div> */}
                        <div className="d-flex flex-column align-items-center w-100 align-items-center container_copy">
                          <h2 className="fw-bold text-center text-white icon-shadow ">
                            {item?.title}
                            {/* {item?.button ? (
                              <a href={item?.button} style={{ fill: "red" }}>
                                {item?.title}
                              </a>
                            ) : (
                              item?.title
                            )} */}
                          </h2>
                          <p className="text-center text-white fs-6">
                            {parse(item?.description)} <br />
                          </p>
                          <p className="text-center text-white fs-6 fw-bold">
                            {item?.email && (
                              <>
                                <a
                                  className="text-white"
                                  href={`mailto:${
                                    item?.email || 'Ummah@ummahrelief.org'
                                  }`}
                                >
                                  {item?.email || 'Ummah@ummahrelief.org'}
                                </a>
                              </>
                            )}
                            {item?.phone && (
                              <>
                                Phone:
                                <a
                                  className="text-white ps-2"
                                  href={`tel: ${
                                    item?.phone || '1-800-713-4482'
                                  }`}
                                >
                                  {item?.phone || '1-800-713-4482'}
                                </a>
                              </>
                            )}
                            {item?.cell && (
                              <>
                                <br />
                                Cell:
                                <a
                                  className="text-white ps-2"
                                  href={`tel: ${item?.cell || '847-622-0574'}`}
                                >
                                  {item?.cell || '847-622-0574'}
                                </a>
                              </>
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                    {item?.button ? (
                      <div className="hidden-button mt-auto p-2">
                        {/* <Link
                          to={`/causes/details/${item?.id}`}
                          style={{
                            textDecoration: "none",
                            borderRadius: "10px",
                          }}
                          className="bg-main fs-5 px-4 py-2 text-white w-100 d-block text-center fw-bold btn-main"
                        >
                          Donate Now
                        </Link> */}
                        <a
                          href={`/donations/zakat`}
                          style={{
                            textDecoration: 'none',
                            borderRadius: '10px',
                            backgroundColor: '#18642f',
                            whiteSpace: 'nowrap',
                          }}
                          className="bg-main fs-6 px-3 py-2 text-white d-block text-center fw-bold btn-main"
                        >
                          Donate Now
                        </a>
                      </div>
                    ) : (
                      ''
                    )}
                  </Card>
                </div>
              );
            })}
          {/* <div className="col-lg-3 col-xl-3 col-smlg-6 col-md-6 col-12 my-3 my-sm-0 my-lg-2 p-2">
            <Card
              className="border-0 rounded-3 text-white h-100"
              style={{ backgroundColor: themeConfig.green }}
            >
              <div className="py-4">
                <div className="d-flex flex-column justify-content-start align-items-center gap-3 px-2">
                  <div
                    className="px-2 text-white text-center"
                    style={{ width: 200, height: 80 }}
                  >
                    <img
                      src={ZelleLogo1}
                      className="icon-shadow "
                      alt="zelle"
                      width={150}
                      height={80}
                    />
                  </div>
                  <div className="d-flex flex-column align-items-start w-100 align-items-center">
                    <h2 className="fw-bold text-center text-white icon-shadow ">
                      Donate By Zelle
                    </h2>
                    <p className="text-center text-white tri-card-p fs-6">
                      You can make a donation by Zelle. Send your donations{' '}
                      <br />
                      to:
                      <a
                        className="text-white ps-2"
                        href={`mailto:${siteEmail || 'Ummah@ummahrelief.org'}`}
                      >
                        {siteEmail || 'Ummah@ummahrelief.org'}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </Card>
          </div>
          <div className="col-lg-3 col-xl-3 col-smlg-6 col-md-6 col-12   my-3 my-sm-0 my-lg-2 p-2">
            <Card
              className="border-0 rounded-3 text-white h-100"
              style={{ backgroundColor: themeConfig.green }}
            >
              <div className="py-4">
                <div className="d-flex flex-column justify-content-start align-items-center gap-3 px-2">
                  <div
                    className="px-2 text-white text-center"
                    style={{ width: 200, height: 80 }}
                  >
                    <img
                      src={PhoneCall}
                      className="icon-shadow "
                      alt=""
                      width={60}
                      height={60}
                    />
                  </div>
                  <div className="d-flex flex-column align-items-start w-100 align-items-center">
                    <h2 className="fw-bold text-center text-white icon-shadow ">
                      Donate By Phone
                    </h2>
                    <p className="text-center text-white tri-card-p fs-6">
                      You can make a donation by telephone <br />
                      Phone:
                      <a
                        className="text-white ps-2"
                        href={`tel: ${uanNumber || '1-800-713-4482'}`}
                      >
                        {uanNumber || '1-800-713-4482'}
                      </a>
                      <br />
                      Cell:
                      <a
                        className="text-white ps-2"
                        href={`tel: ${mobileNumber || '847-622-0574'}`}
                      >
                        {mobileNumber || '847-622-0574'}
                      </a>
                      <br />
                    </p>
                  </div>
                </div>
              </div>
            </Card>
          </div>
          <div className="col-lg-3 col-xl-3 col-smlg-6  col-md-6 col-12  my-3 my-sm-0 my-lg-2 p-2">
            <Card
              className="border-0 rounded-3 text-white h-100"
              style={{ backgroundColor: themeConfig.green }}
            >
              <div className="py-4">
                <div className="d-flex flex-column justify-content-start align-items-center gap-3 px-2">
                  <div className="px-2 text-white">
                    <img
                      src={Zakat3}
                      className="icon-shadow "
                      alt=""
                      width={80}
                      height={80}
                    />
                  </div>
                  <div className="d-flex flex-column align-items-start w-100 align-items-center">
                    <h2 className="fw-bold text-center text-white icon-shadow ">
                      <a
                        href="https://ummahrelief.org/donations/zakat"
                        style={{ textDecoration: 'none' }}
                      >
                        Calculate & Pay Zakat Online
                      </a>
                    </h2>
                    <p className="text-center text-white tri-card-p fs-6">
                      By donating Zakat/Sadaqa we can distribute Food packages
                      to the most needy people. Your Online Zakat Donation is
                      needed.
                    </p>
                  </div>
                </div>
              </div>
            </Card>
          </div>
          <div className="col-lg-3 col-xl-3 col-smlg-6 col-md-6 col-12 my-3 my-sm-0 my-lg-2 p-2">
            <Card
              className="border-0 rounded-3 text-white h-100"
              style={{ backgroundColor: themeConfig.green }}
            >
              <div className="py-4">
                <div className="d-flex flex-column justify-content-start align-items-center gap-3 px-2">
                  <div className="px-2 text-white">
                    <img
                      src={LocationPin}
                      className="icon-shadow "
                      alt=""
                      width={80}
                      height={80}
                    />
                  </div>
                  <div className="d-flex flex-column align-items-start w-100 align-items-center">
                    <h2 className="fw-bold text-center text-white icon-shadow ">
                      By Postal Mail Address
                    </h2>
                    <p className="text-center text-white">
                      <div
                        className="text-center text-white tri-card-p fs-6"
                        dangerouslySetInnerHTML={{
                          __html:
                            location ||
                            'Ummah Relief International<br>339 W River Rd, Elgin, IL 60123,<br>United States',
                        }}
                      />
                    </p>
                  </div>
                </div>
              </div>
            </Card>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default DonationWaysSeparator;
