import {
  DonationForm,
  DonnationPoster,
  SpecialRequestForm,
} from './components/DonationComponent';
import React, { useEffect, useState } from 'react';
import DeclineInfo from './components/DonationComponent/DeclineInfo';
import CreditCardForm from './components/CreditCardForm';
import DonationService from '../../services/donation.service';
import EmployeeMatchingGiftProgram from './components/EmployeeMatchingGiftProgram';
import ErrorFormat from '../../utils/ErrorFormatting';
import FormValidator from '../../utils/validation';
import PersonalInfoForm from './components/PersonalInfoForm';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { wait } from '../../utils/constants';
import ACHPaymentForm from './components/ACHPaymentForm';
import EcheckRadioButtons from '../../components/EcheckRadioButtons';
import PlaidLink from '../../components/CreatePlaidLink';

const MonthlyDonation = () => {
  const navigate = useNavigate();
  const [donationType, setDonationType] = useState([]);
  const [initialDonationType, setInitialDonationType] = useState([]);
  const [specialRequest, setSpecialRequest] = useState([]);
  const [specialRequestBackup, setSpecialRequestBackup] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalSpecialAmount, setTotalSpecialAmount] = useState(0);
  const [totalDonation, setTotalDonation] = useState([]);
  const [totalSpecialDonations, setTotalSpacialDonation] = useState([]);
  const [personalInfo, setPersonalInfo] = useState({});
  const [creditCardInfo, setCreditCardInfo] = useState({});
  const [finalDonationData, setFinalDonationData] = useState({});
  const [disableSubmitButton, setDisableSubmitButton] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [currency] = useState('usd');
  const [submissionState, setSubmissionState] = useState(0);
  const [isResetForm, setIsResetForm] = useState(false);
  const [employeeMatchingGiftProgram, setEmployeeMatchingGiftProgram] =
    useState({});
  const [isEcheck, setIsEcheck] = useState(false);
  const [stripeToken, setStripeToken] = useState(null);
  const handleStripeTokenReceived = (stripeToken) => {
    setStripeToken(stripeToken);
    console.log({ stripeToken });
  };
  const handleSum = (sum) => {
    setTotalAmount(sum);
  };
  const [achInfo, setAchInfo] = useState({});
  const [errors, setErrors] = useState({});
  const getDonationType = () => {
    DonationService.getMonthlyDonationTypes().then((res) => {
      setDonationType(res);
      setInitialDonationType(res);
    });
    DonationService.getSpecialDonationTypes().then((res) => {
      setSpecialRequest(res);
      setSpecialRequestBackup(res);
    });
  };

  // console.log("finalDonationData", finalDonationData);

  const scrollTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };

  const extendSubmissionState = () => {
    setSubmissionState((state) => state + 1);
  };

  const startSubmit = () => {
    setSubmissionState(1);
    setTimeout(() => {
      setSubmissionState(0);
    }, 1000);
  };
  // console.log('Ahsan', achInfo);
  useEffect(() => {
    // console.log(
    //   'OneTimeDonation | Validation submissionState',
    //   submissionState
    // );

    const totalForms = 1;
    if (isEcheck && stripeToken) {
      // extendSubmissionState();
      setAchInfo({ stripeToken });
    }
    // console.log('subm1', submissionState);
    if (submissionState >= totalForms + 1) {
      if (isEcheck) {
        if (stripeToken) {
          handleSubmit();
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'You did not connect a bank account!',
          });
        }
      } else {
        // console.log('subm3', submissionState);
        handleSubmit();
      }
    }
  }, [submissionState]);

  const checkIsDonation = () => {
    {
      startSubmit();
    }
  };

  const handleSubmit = () => {
    setIsLoading(true);

    if (
      finalDonationData.monthly.length === 0 &&
      finalDonationData.special.length === 0
    ) {
      setIsLoading(false);
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'You did not select any donatation amount!',
      });
    } else {
      // console.log('Ahsan3', finalDonationData);

      DonationService.submitDonation({
        matchingGift: employeeMatchingGiftProgram,
        personal: personalInfo,
        card: creditCardInfo,
        ach: achInfo,
        isEcheck,
        special: totalSpecialDonations,
        monthly: totalDonation,
        donation_of: {
          oneTime: false,
          monthly: true,
          zakat_fitra_donation: false,
          qurbani: false,
          cause: false,
          special: totalSpecialDonations.length > 0,
        },
        payment_through: isEcheck ? 'ach' : 'card',
        currency: currency,
        is_special_request: '0',
        duration_id: 2,
      })
        .then((data) => {
          if (data.payment.status && data.email.status) {
            let errorExists;

            if (!data.donor.status) errorExists = true;
            else if (!data.cc_details.status) errorExists = true;
            else errorExists = !data.monthly.status;

            Swal.fire({
              title: 'Thank you for your donation!',
              text: `${
                errorExists
                  ? 'Some information cannot saved successfully but donation done successfully, if it concern do contact Ummah Relief. A verification email has been send to you with details'
                  : 'A confirmation email has been sent to the email provided.'
              }`,
              icon: 'success',
              allowOutsideClick: false,
              allowEscapeKey: false,
            }).then((r) => window.location.reload());
          } else {
            setIsLoading(false);
            let errorMessages;
            if (!data.payment.status) errorMessages = data.payment.message;
            else if (!data.email.status) errorMessages = data.email.message;

            Swal.fire({
              icon: 'error',
              title: 'Error occurred',
              html: ErrorFormat.validationErrors(errorMessages),
            });
          }
        })
        .catch((err) => {
          setIsLoading(false);
          // console.log(err.response.data.message)
          if (err.response.data.message) {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: err.response.data.message,
            });
          }
        });
    }
  };

  useEffect(() => {
    document.title = 'Monthly Donations | Ummah Relief';
    getDonationType();
    scrollTop();
  }, []);
  // console.log('Ahsan2', finalDonationData);
  useEffect(() => {
    setFinalDonationData({
      matchingGift: employeeMatchingGiftProgram,
      personal: personalInfo,
      card: creditCardInfo,
      ach: achInfo,
      isEcheck,
      special: totalSpecialDonations,
      monthly: totalDonation,
      donation_of: {
        oneTime: false,
        monthly: true,
        zakat_fitra_donation: false,
        qurbani: false,
        cause: false,
        special: totalSpecialDonations.length > 0,
      },
      payment_through: isEcheck ? 'ach' : 'card',
      currency: currency,
      is_special_request: '0',
      duration_id: 2,
    });
    if (
      FormValidator.requiredFieldCheck(finalDonationData, 'monthly').includes(
        false
      )
    ) {
      setDisableSubmitButton(true);
    } else {
      setDisableSubmitButton(false);
    }
  }, [
    totalDonation,
    personalInfo,
    creditCardInfo,
    achInfo,
    currency,
    totalSpecialDonations,
    employeeMatchingGiftProgram,
  ]);

  const isValidated = (values) => {
    if (typeof values === 'object') {
      return Object.values(values).every(
        (x) => x !== null || x !== '' || x !== 0
      );
    } else {
      return values !== '' || values !== null;
    }
  };
  const resetForm = async () => {
    setIsResetForm(true);
    setDonationType(initialDonationType);
    setTotalAmount(0);
    setTotalSpecialAmount(0);
    setTotalDonation([]);
    setTotalSpacialDonation([]);
    setPersonalInfo({});
    setCreditCardInfo({});
    setAchInfo({});
    setFinalDonationData({});
    setIsEcheck(false);
    setSpecialRequest(specialRequestBackup);
    await wait(1);
    setIsResetForm(false);
  };
  return (
    <div className="row">
      <div className="col-xl-8 col-lg-8 col-md-12">
        {!isResetForm && (
          <SpecialRequestForm
            options={specialRequest}
            setOptions={setSpecialRequest}
            handleSum={(sum) => setTotalSpecialAmount(sum)}
            setTotalDonation={(total) => setTotalSpacialDonation(total)}
            totalDonation={totalSpecialDonations}
            totalAmount={totalSpecialAmount}
          />
        )}
        {!isResetForm && (
          <DonationForm
            donationOption={donationType}
            setDonationOption={setDonationType}
            handleSum={(sum) => setTotalAmount(sum)}
            totalAmount={totalAmount}
            setTotalDonation={(total) => setTotalDonation(total)}
            totalDonation={totalDonation}
            title="Monthly Donation"
          />
        )}
        <h4 className="fw-bold">Total: ${totalAmount + totalSpecialAmount}</h4>
      </div>
      <div className="col-xl-4 col-lg-4 col-md-12 pt-5 pt-lg-0 pt-xl-0 pt-xxl-0">
        {!isResetForm && (
          <PersonalInfoForm
            submissionState={submissionState}
            extendSubmissionState={extendSubmissionState}
            personalInfo={personalInfo}
            setPersonalInfo={(info) => setPersonalInfo(info)}
          />
        )}
        {!isResetForm && (
          <EmployeeMatchingGiftProgram
            setEmployeeMatchingGiftProgram={(info) =>
              setEmployeeMatchingGiftProgram(info)
            }
          />
        )}
        <EcheckRadioButtons
          isEcheck={isEcheck}
          setIsEcheck={(echeck) => setIsEcheck(echeck)}
        />
        {!isResetForm && !isEcheck && (
          <CreditCardForm
            submissionState={submissionState}
            extendSubmissionState={extendSubmissionState}
            creditCardInfo={creditCardInfo}
            setCreditCardInfo={(creditCard) => setCreditCardInfo(creditCard)}
          />
        )}
        {!isResetForm && isEcheck && (
          <PlaidLink onStripeTokenReceived={handleStripeTokenReceived} />
          // <ACHPaymentForm
          //   submissionState={submissionState}
          //   extendSubmissionState={extendSubmissionState}
          //   achInfo={achInfo}
          //   setAchInfo={(ach) => setAchInfo(ach)}
          //   errors={errors}
          // />
        )}

        <div className="d-flex justify-content-start flex-wrap align-items-center gap-3">
          <button
            className="text-uppercase btn-green border-0 py-1 px-3"
            style={{
              borderRadius: '20px',
              borderWidth: '2px',
              fontSize: '14px',
            }}
            disabled={isLoading}
            onClick={checkIsDonation}
            type="button"
          >
            {isLoading ? (
              <>
                Proccessing{' '}
                <span
                  className="spinner-border"
                  role="status"
                  style={{
                    height: '14px',
                    width: '14px',
                  }}
                ></span>
              </>
            ) : (
              'Submit'
            )}
          </button>
          <button
            onClick={resetForm}
            className="text-uppercase btn-orang border-0 py-1 px-3"
            style={{
              borderRadius: '20px',
              borderWidth: '2px',
              fontSize: '14px',
            }}
            type="button"
            disabled={isLoading}
          >
            Reset
          </button>
          <button
            type="button"
            className="text-uppercase btn-gray text-white border-0 py-1 px-3"
            style={{
              borderRadius: '20px',
              borderWidth: '2px',
              fontSize: '14px',
            }}
            onClick={() =>
              navigate(`/`, {
                replace: true,
              })
            }
            disabled={isLoading}
          >
            Cancel
          </button>
        </div>
        {/* <div className='d-flex justify-content-end gap-3'>
          <button
            className='text-uppercase btn btn-outline-light text-white bg-success text-success fw-bold px-4 tri-card-p text-white mx-auto mx-sm-auto mx-md-0 mx-lg-0 mx-xl-0 theme_btn theme_btn_bg '
            style={{
              borderRadius: '20px',
              borderWidth: '2px',
              fontSize: '12px',
            }}
            // className="btn btn-success btn-lg"
            // style={{ borderRadius: 30 }}
            type='button'
            disabled={isLoading}
            onClick={() => startSubmit()}
          >
            {isLoading ? 'Processing...' : 'Donate Now'}
          </button>
        </div> */}
        <DeclineInfo type={'Monthly'} />
        <DonnationPoster />
      </div>
    </div>
  );
};

export default MonthlyDonation;
