import React, { useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { themeConfig } from '../../../../config/theme.config';
import { CharityImage, YouthubeThumbnail } from '../../../../utils/Images';
import { Link } from 'react-router-dom';
import { getAssetUrl } from '../../../../utils/constants';
/* import ReactPlayer from 'react-player'; */

const AboutSection = ({ aboutUsData, onImageClick = () => {} }) => {
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);
  return (
    <>
      <div className="my-5 py-3 pt-md-5">
        <div className="container my-2">
          <h2 className="text-center fw-bold mb-3 mb-lg-4 mb-xl-4 mb-xxl-4">
            {aboutUsData?.title || 'About Us'}
          </h2>
          <div className="row py-5">
            <div className="about_sec">
              <div className="container">
                <div className="row h-100 align-items-center">
                  <div className="col-lg-6 col-md-12">
                    <div className="about_cont">
                      {/*  <h2 className="heading2">The Causes We Care About</h2> */}
                      <p
                        dangerouslySetInnerHTML={{
                          __html:
                            aboutUsData?.description ||
                            `Ummah Relief International was established in 1994 to help people
                            in need around the world. We are a dedicated relief organization
                            providing a variety of humanitarian services such as food,
                            clothing, medical treatment, and schools. We bring help and hope
                            to thousands of victims of natural and human disasters.
                            <br />
                            <br />
                            Each year, millions of men, women, and children are impacted by
                            floods, earthquakes, tornadoes, hurricanes and other disasters.
                            Every day Ummah Relief International Humanitarian Relief programs
                            help people relieve some of the suffering in the world. With your
                            help, Ummah Relief International responds to natural disasters all
                            over the world with quick and targeted action.`,
                        }}
                      ></p>
                      <div className="text-center">
                        <a
                          className="btn btn-blue_bordr my-3 mx-2 ps-auto"
                          href="/donations/oneTime"
                        >
                          Donate Now
                          <span>
                            <i className="fa fa-long-arrow-right ps-2"></i>
                          </span>
                        </a>
                        <a
                          className="btn btn-blue_bordr my-3 "
                          href={aboutUsData?.button || '/about'}
                        >
                          Read More
                          <span>
                            <i className="fa fa-long-arrow-right ps-2"></i>
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <div
                      className="abut_imgs my-5 cursor-pointer"
                      style={{ position: 'relative', cursor: 'pointer' }}
                      onClick={onImageClick}
                    >
                      <img
                        src={
                          YouthubeThumbnail
                          /* aboutUsData?.about_us_image
                            ? getAssetUrl(aboutUsData?.about_us_image)
                            : CharityImage */
                        }
                        className="about1 img-fluid"
                      />
                      <div
                        style={{
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                        }}
                        id="play-video"
                        className="video-play-button"
                      >
                        <span></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="my-5 py-3 pt-md-5">
        <div className="container  my-2">
          <div className="d-flex flex-column  flex-lg-row flex-xl-row  justify-content-between align-items-top row px-2">
            <div className=" col-lg-6 col-md-12">
              <h2 className="text-center fw-bold mb-3 mb-lg-5 mb-xl-5 mb-xxl-5">
                {aboutUsData?.title || "About Us"}
              </h2>
              <p
                className="tri-card-p text-center fs-5"
                style={{ letterSpacing: "1px", fontWeight: 400 }}
                dangerouslySetInnerHTML={{
                  __html:
                    aboutUsData?.description ||
                    `Ummah Relief International was established in 1994 to help people
              in need around the world. We are a dedicated relief organization
              providing a variety of humanitarian services such as food,
              clothing, medical treatment, and schools. We bring help and hope
              to thousands of victims of natural and human disasters.
              <br />
              <br />
              Each year, millions of men, women, and children are impacted by
              floods, earthquakes, tornadoes, hurricanes and other disasters.
              Every day Ummah Relief International Humanitarian Relief programs
              help people relieve some of the suffering in the world. With your
              help, Ummah Relief International responds to natural disasters all
              over the world with quick and targeted action.`,
                }}
              ></p>
              <div className="text-center pt-4 ">
                <Link
                  to={aboutUsData?.button || "/about"}
                  className="text-uppercase bg-main fw-bold px-5 py-2 text-white mx-auto mx-sm-auto mx-md-0 mx-lg-0 mx-xl-0 btn-main"
                  style={{
                    borderRadius: "50px",
                    borderWidth: "2px",
                    fontSize: "1.4rem",
                    textDecoration: "none",
                  }}
                >
                  <span className="icon-shadow">Read More </span>
                </Link>
              </div>
            </div>

            <div className="col-lg-6 col-md-12">
              <div className="d-flex justify-content-center">
                <img
                  src={
                    aboutUsData?.about_us_image
                      ? getAssetUrl(aboutUsData?.about_us_image)
                      : CharityImage
                  }
                  // className="w-100 h-100 "
                  style={{ maxWidth: "100%" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* 
      <div className="my-5 py-5 pt-md-5">
        <div className="container d-flex flex-align-center flex-justify-center">
          <div className="about-text text-center col-lg-6 col-md-12">
            <h2 className="text-center fw-bold mb-3 mb-lg-5 mb-xl-5 mb-xxl-5">
              {aboutUsData?.title || "About Us"}
            </h2>
            <p
              className="tri-card-p text-center fs-5 mb-5"
              style={{ letterSpacing: "1px", fontWeight: 400 }}
              dangerouslySetInnerHTML={{
                __html:
                  aboutUsData?.description ||
                  `Ummah Relief International was established in 1994 to help people
              in need around the world. We are a dedicated relief organization
              providing a variety of humanitarian services such as food,
              clothing, medical treatment, and schools. We bring help and hope
              to thousands of victims of natural and human disasters.
              <br />
              <br />
              Each year, millions of men, women, and children are impacted by
              floods, earthquakes, tornadoes, hurricanes and other disasters.
              Every day Ummah Relief International Humanitarian Relief programs
              help people relieve some of the suffering in the world. With your
              help, Ummah Relief International responds to natural disasters all
              over the world with quick and targeted action.`,
              }}
            ></p>
            <div className="col-lg-6 col-md-12">
              <Link
                to={aboutUsData?.button || "/about"}
                className="text-uppercase bg-main fw-bold px-5 py-2 text-white mx-auto mx-sm-auto mx-md-0 mx-lg-0 mx-xl-0 btn-main "
                style={{
                  borderRadius: "50px",
                  borderWidth: "2px",
                  fontSize: "1.4rem",
                  textDecoration: "none",
                }}
              >
                <span className="icon-shadow">Read More </span>
              </Link>
            </div>
          </div>
          <div className="about-image">
            <img
              src={
                aboutUsData?.about_us_image
                  ? getAssetUrl(aboutUsData?.about_us_image)
                  : CharityImage
              }
              // className="w-100 h-100 "
              style={{ maxWidth: "100%" }}
            />
          </div>
        </div>
      </div> */}

      <Modal isOpen={modal} size="lg" toggle={toggle}>
        <ModalHeader toggle={toggle}>Interview</ModalHeader>
        <ModalBody className="d-flex justify-content-center align-items-center">
          <iframe
            width="700"
            style={{ height: '50vh' }}
            src="https://www.youtube.com/embed/xsEEEjsyZgM?autoplay=1"
            allowFullScreen
            allow="autoplay"
          ></iframe>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default AboutSection;
