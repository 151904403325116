import API from "../utils/api";

const getComments = (blogId) => {
    return API.get(`/getCommentsByBlogId/${blogId}`)
        .then((response) => {
            return response.data;
        });
};

const storeComment = (data) => {
    return API.post('/comments', data)
        .then((response) => {
            return response.data;
        });
};

const CommentService = {
    getComments,
    storeComment
};

export default CommentService;