import {
  Carousel,
  CarouselCaption,
  CarouselControl,
  CarouselIndicators,
  CarouselItem,
} from 'reactstrap';
import React, { useEffect, useState } from 'react';

import CauseService from '../../../../services/causes.service';
import { Link } from 'react-router-dom';
import { LogoLoader } from '../../../../utils/Images';

function CarouselComponent(props) {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);
  const [items, setItems] = useState(props?.carouselData || []);
  /* const getAllCauses = () => {
    CauseService.getCarousel().then((res) => {
      setItems(res);
    });
  };
  useEffect(() => {
    getAllCauses();
  }, []); */
  useEffect(() => {
    setItems(props?.carouselData);
  }, [props?.carouselData]);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const loader = [
    {
      src: LogoLoader,
      altText: 'Loader',
      caption: 'Please Wait',
      title: 'Loading...',
      key: 1,
    },
  ];

  // document.getElementsByClassName('carousel-caption')[0].classList.remove('d-none')

  const captionText = (caption, donate_button, readMore_button) => {
    return (
      <div className="d-flex flex-column justify-content-center align-items-center gap-2">
        {/*  <span style={{ lineHeight: 1 }}>{caption}</span> */}
        <div className="">{caption}</div>
        <div className="gap-2 d-flex flex-wrap justify-content-center">
          <Link
            to={donate_button}
            className="text-uppercase btn btn-outline-secondary carousel-btn-text fw-bold px-sm-4 px-2 py-sm-2 py-1 carousel-btn border-0 mx-1"
          >
            <span className="icon-shadow "> Donate Now</span>
          </Link>
          <Link
            to={readMore_button}
            className="text-uppercase btn btn-outline-secondary carousel-btn-text fw-bold px-sm-4 px-2 py-sm-2 py-1 carousel-btn border-0 mx-1"
          >
            <span className="icon-shadow ">Read More</span>
          </Link>
        </div>
      </div>
    );
  };

  return (
    <Carousel
      activeIndex={activeIndex}
      next={next}
      previous={previous}
      {...props}
      // style={{height: '60vh', width: '100vw'}}
    >
      <CarouselIndicators
        items={items}
        activeIndex={activeIndex}
        onClickHandler={goToIndex}
      />
      {items.length > 0
        ? items.map((item, index) => {
            return (
              <CarouselItem
                onExiting={() => setAnimating(true)}
                onExited={() => setAnimating(false)}
                key={index}
                className="carosel-image"
              >
                {/*<img src={`data:image/png;base64, ${item.src}`} alt={item.altText}*/}
                {/*     style={{height: '60vh', width: '100%'}}/>*/}
                <div
                  className="carosel-items-customization"
                  style={{
                    backgroundImage: `url('${item.image}')`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                  }}
                ></div>
                {false && (
                  <img
                    /* src={`${process.env.REACT_APP_SERVER_PUBLIC_URL}/admin/uploads/cause/${item.image}`} */
                    src={`${item.image}`}
                    alt={item.title}
                    className="carosel-items-customization"
                  />
                )}

                <div className="slider-content gap-4">
                  <div className="display-4 fw-bold text-orange pb-2 icon-shadow ">
                    {item.title}
                  </div>
                  <div className="text-capt icon-shadow ">
                    {captionText(
                      item.short_desc,
                      item.donate_button,
                      item.readMore_button
                    )}
                  </div>
                </div>
                {/* <div className='carousel-caption' id='carsoule-caption'>
                  <h3>{item.title}</h3>
                  <div className='display-3 fw-bold text-orange'>
                    {item.title}
                  </div>
                  <span className='text-capt'>
                    {captionText(item.caption, item.id)}
                  </span>
                </div> */}
                {/*<CarouselCaption*/}
                {/*    captionText={captionText(item.caption, item.id)}*/}
                {/*    captionHeader={item.title}*/}
                {/*/>*/}
              </CarouselItem>
            );
          })
        : loader.map((item, index) => {
            return (
              <CarouselItem
                onExiting={() => setAnimating(true)}
                onExited={() => setAnimating(false)}
                key={index}
              >
                <div className="d-flex justify-content-center">
                  <img
                    src={item.src}
                    alt={item.altText}
                    style={{ height: '500px', width: '500px' }}
                  />
                </div>
                <CarouselCaption
                  captionText={captionText(item.caption, 0)}
                  captionHeader={item.title}
                />
              </CarouselItem>
            );
          })}
      <CarouselControl
        direction="prev"
        directionText="Previous"
        onClickHandler={previous}
      />
      <CarouselControl
        direction="next"
        directionText="Next"
        onClickHandler={next}
      />
    </Carousel>
  );
}

export default CarouselComponent;
