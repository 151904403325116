import React from 'react';

const DeclineInfo = ({ type }) => {
  return (
    <>
      <div className='w-100 py-3 pb-0 '>
        <p className='text-dark' style={{ fontSize: '100%' }}>
          If for some reason your card is declined , it is due to security
          reasons. Please call our office at 1-800-713-4482 to process your
          {` ${type} `} donation over the phone.
        </p>
      </div>
    </>
  );
};

export default DeclineInfo;
