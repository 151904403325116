import { Modal } from '@mui/material';
import React from 'react';
import ReactPlayer from 'react-player/youtube';

function YoutubeVideoPlayerModal({
  open = false,
  onClose = () => {},
  url = '',
}) {
  return (
    <Modal open={open} onClose={onClose}>
      <div className="youtube-video-modal">
        <ReactPlayer
          className="react-player"
          url={url}
          width="100%"
          height="100%"
          controls={true}
        />
      </div>
    </Modal>
  );
}

export default YoutubeVideoPlayerModal;
