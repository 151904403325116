import { Card, CardBody } from 'reactstrap';
import {
  FlameIcon,
  Globe,
  HandShake,
  VisionImg,
  WaterDrop,
} from '../../../../utils/Images';

import React from 'react';
import { themeConfig } from '../../../../config/theme.config';

const VisionGoal = () => {
  return (
    <div className="mt-5 mb-5">
      <div className="container pt-5">
        <div className="row">
          <div className="col-12 my-0 my-lg-2">
            <div
              className="fw-bold text-decoration-underline"
              style={{ fontSize: 22 }}
            >
              Introduction
            </div>

            <p className="tri-card-p">
              At Ummah Relief International, safeguarding the privacy and
              security of our valued donors, dedicated volunteers, cherished
              beneficiaries, and esteemed website visitors is paramount to our
              mission. With unwavering dedication, this Privacy Policy outlines
              our steadfast commitment to transparent practices governing the
              collection, utilization, and safeguarding of your personal
              information. Please review the following provisions to understand
              how we uphold your privacy with the utmost diligence and
              integrity.
            </p>
          </div>
          {/* 2 */}
          <div className="col-12 my-0 my-lg-2">
            <div
              className="fw-bold text-decoration-underline"
              style={{ fontSize: 22 }}
            >
              Information Collection
            </div>

            <p className="tri-card-p">
              Ummah Relief International prioritizes transparency and
              accountability in our information collection practices. Personal
              information, including names, contact details, and payment
              information, is meticulously gathered primarily through
              meticulously crafted donation forms and user interactions on our
              website. Furthermore, we may procure supplementary data through
              direct communications and active participation in our events. Rest
              assured, every piece of information collected is treated with the
              utmost confidentiality and utilized solely for the noble purpose
              of furthering our humanitarian mission.
            </p>
          </div>
          {/* 3 */}
          <div className="col-12 my-0 my-lg-2">
            <div
              className="fw-bold text-decoration-underline"
              style={{ fontSize: 22 }}
            >
              Use of Information
            </div>

            <p className="tri-card-p">
              Your invaluable information serves as the cornerstone of our
              operations at Ummah Relief International. With meticulous care, we
              utilize the data provided to facilitate the seamless processing of
              donations, ensuring that each contribution makes a tangible
              difference in the lives of those in need. Additionally, your
              details enable us to maintain open lines of communication,
              delivering timely updates on our ongoing initiatives and the
              impactful results they yield. As stewards of your trust, we employ
              your information to administer our programs with precision and
              efficacy, tailoring our efforts to address the most pressing
              humanitarian needs worldwide. Beyond immediate functions, your
              input empowers us to continuously refine and enhance our services,
              fostering a culture of continuous improvement as we strive towards
              our shared mission of alleviating suffering and fostering hope in
              communities around the globe.
            </p>
          </div>
          {/* 4 */}
          <div className="col-12 my-0 my-lg-2">
            <div
              className="fw-bold text-decoration-underline"
              style={{ fontSize: 22 }}
            >
              Information Sharing and Disclosure
            </div>

            <p className="tri-card-p">
              At Ummah Relief International, we prioritize your privacy. We
              never sell or rent personal information. Occasionally, we may
              share data with trusted third-party partners for operational needs
              or legal compliance. Rest assured, all partners undergo thorough
              vetting to meet our strict privacy standards, ensuring the
              integrity and confidentiality of your information.
            </p>
          </div>
          {/* 5 */}
          <div className="col-12 my-0 my-lg-2">
            <div
              className="fw-bold text-decoration-underline"
              style={{ fontSize: 22 }}
            >
              Data Security
            </div>

            <p className="tri-card-p">
              We employ a comprehensive array of robust security measures
              designed to fortify our defenses against unauthorized access,
              alteration, or destruction of your data. Our stringent protocols
              encompass state-of-the-art secure data storage practices and the
              implementation of encrypted communication channels, ensuring that
              your sensitive information remains shielded from any potential
              threats or breaches. By continuously monitoring and updating our
              security infrastructure, we remain steadfast in our commitment to
              upholding the highest standards of data protection, providing you
              with peace of mind as you engage with us in support of our
              humanitarian mission.
            </p>
          </div>
          {/* 6 */}
          <div className="col-12 my-0 my-lg-2">
            <div
              className="fw-bold text-decoration-underline"
              style={{ fontSize: 22 }}
            >
              Your Rights and Choices
            </div>

            <p className="tri-card-p">
              Ummah Relief International firmly believes in empowering you with
              control over your personal information. You hold the right to
              access, rectify, or request the deletion of any inaccuracies
              within your data. Additionally, you retain the option to object to
              or restrict specific processing activities carried out with your
              information. Your privacy preferences are of utmost importance to
              us, and we are committed to honoring your choices. To exercise any
              of these rights or discuss your privacy concerns further, please
              don’t hesitate to reach out to us using the contact details
              provided below. Your trust and satisfaction are integral to our
              mission, and we’re here to ensure that your experience with us is
              both secure and transparent.
            </p>
          </div>
          {/* 7 */}
          <div className="col-12 my-0 my-lg-2">
            <div
              className="fw-bold text-decoration-underline"
              style={{ fontSize: 22 }}
            >
              Cookies and Tracking Technology
            </div>

            <p className="tri-card-p">
              At Ummah Relief International, we utilize cookies and similar
              tracking technologies on our website to provide an enhanced user
              experience and to analyze website traffic patterns. These
              technologies allow us to tailor content and advertisements to your
              interests, ensuring that your interaction with our platform is
              personalized and relevant. By understanding how visitors navigate
              our site, we can continuously optimize its functionality and
              design to better serve your needs. We respect your right to manage
              your cookie preferences and offer you the flexibility to adjust
              settings through your browser controls. For more details on how we
              use cookies and tracking technologies, and to review our full
              cookie policy, please click <a>here</a>.
            </p>
          </div>
          {/* 8 */}
          <div className="col-12 my-0 my-lg-2">
            <div
              className="fw-bold text-decoration-underline"
              style={{ fontSize: 22 }}
            >
              Policy Updates
            </div>

            <p className="tri-card-p">
              Our commitment to transparency and accountability extends to our
              privacy practices, which may be periodically updated to reflect
              changes in our operations or legal obligations. These updates are
              aimed at ensuring that our policies remain in alignment with our
              core values and continue to provide you with the highest standards
              of privacy protection. In the event of any significant changes to
              this policy, we will notify you promptly through the contact
              information you have provided, so you can stay informed about how
              your personal information is handled. Additionally, the updated
              policy will be readily accessible on our website, where you can
              review it at your convenience. Your trust and confidence are of
              utmost importance to us, and we remain dedicated to keeping you
              informed and empowered regarding your privacy rights.
            </p>
          </div>
          {/* 9 */}
          <div className="col-12 my-0 my-lg-2">
            <div
              className="fw-bold text-decoration-underline"
              style={{ fontSize: 22 }}
            >
              Contact Information
            </div>

            <p className="tri-card-p">
              For any inquiries, clarifications, or concerns regarding this
              Privacy Policy, or to exercise your rights regarding your personal
              information, please reach out to us at the following contact
              details:
              <br />
              <div className="d-flex flex-column gap-2 mt-2">
                <div>
                  <span className="fw-bold">Email: </span>
                  <a href="mailto:ummah@ummahrelief.org">
                    ummah@ummahrelief.org
                  </a>
                </div>
                <div>
                  <span className="fw-bold">Address: </span>
                  <span>339 W River Rd, Elgin IL 60123</span>
                </div>
                <div>
                  <span className="fw-bold">Mailing Address: </span>
                  <span>P.O. Box 1426, Elgin, IL 60121</span>
                </div>
                <div>
                  <span className="fw-bold">Phone: </span>
                  <span>1-800-713-4482</span>
                </div>
              </div>
            </p>
          </div>
          {/* 10 */}
          <div className="col-12 my-0 my-lg-2">
            <div
              className="fw-bold text-decoration-underline"
              style={{ fontSize: 22 }}
            >
              Acknowledgement and Consent
            </div>

            <p className="tri-card-p">
              By engaging with our services and exploring our website, you
              affirm that you have reviewed this Privacy Policy, thereby
              demonstrating your conscientious acknowledgment of its contents.
              By continuing to interact with our platform, you provide explicit
              consent for the collection, processing, and utilization of your
              personal information in accordance with the provisions outlined
              herein. Your informed consent underscores our mutual commitment to
              transparency and trust, ensuring that your privacy rights are
              respected and upheld throughout your journey with Ummah Relief
              International.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VisionGoal;
