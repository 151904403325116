import { Link, NavLink, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

import CausesService from '../../../../services/causes.service';
import { DonationBg } from '../../../../utils/Images';
import moment from 'moment';
import MediaSliderCause from '../MediaSliderCause';

const CauseDetailNavTabs = ({ cause }) => {
  const media = cause?.cause_media || [];
  const [tabActive, setTabActive] = useState({ description: true });
  const [firstLatestCause, setFirstLatestCause] = useState({});
  const [secondLatestCause, setSecondLatestCause] = useState({});
  const [causeDonations, setCauseDonations] = useState([]);
  const params = useParams();
  const handleTabActive = (tab) => {
    if (tab === 'description') {
      setTabActive({ description: true });
    } else if (tab === 'updates') {
      setTabActive({ updates: true });
    } else if (tab === 'donations') {
      setTabActive({ donations: true });
    } else if (tab === 'media') {
      setTabActive({ media: true });
    } else {
      setTabActive({ reviews: true });
    }
  };

  const getRecentLatestCauses = () => {
    let number = 0;
    CausesService.getCauses(1).then((res) => {
      res.data.map((item, index) => {
        if (item.id !== parseInt(params.causeID)) {
          if (number === 0) {
            // console.log(index)
            setFirstLatestCause(item);
          }
          if (number === 1) {
            setSecondLatestCause(item);
          }
          number++;
        }
      });
    });
  };

  const getCauseDonation = () => {
    CausesService.getDonationByCauseID(params.causeID).then((data) => {
      setCauseDonations(data);
    });
  };

  useEffect(() => {
    getRecentLatestCauses();
    getCauseDonation();
  }, [params]);

  return (
    <section
      className="our-overview-area pos-rel  wow fadeInUp2  animated my-5 align-top"
      data-wow-delay=".1s"
      style={{
        visibility: 'visible',
        animationDelay: '0.1s',
        animationName: 'fadeInUp2',
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col-xl-12">
            {/*     Tabs buttons        */}
            <ul
              className="nav nav-tabs nav-tabs-02 gap-1 gap-sm-2 gap-md-2 gap-lg-3 gap-xl-3 gap-xxl-3"
              id="myTab"
              role="tablist"
            >
              <li className="nav-item">
                <button
                  className={`nav-link theme_btn ${
                    tabActive.description ? 'active' : ''
                  }`}
                  id="home-tab"
                  data-toggle="tab"
                  role="tab"
                  aria-controls="description"
                  aria-selected={tabActive.description}
                  onClick={() => handleTabActive('description')}
                >
                  Description
                </button>
              </li>
              <li className="nav-item">
                <button
                  className={`nav-link theme_btn ${
                    tabActive.updates ? 'active' : ''
                  }`}
                  id="profile-tab"
                  data-toggle="tab"
                  role="tab"
                  aria-controls="updates"
                  aria-selected={tabActive.updates}
                  onClick={() => handleTabActive('updates')}
                >
                  Updates
                </button>
              </li>
              <li className="nav-item">
                <button
                  className={`nav-link theme_btn ${
                    tabActive.donations ? 'active' : ''
                  }`}
                  id="contact-tab"
                  data-toggle="tab"
                  role="tab"
                  aria-controls="donations"
                  aria-selected={tabActive.donations}
                  onClick={() => handleTabActive('donations')}
                >
                  Donations
                </button>
              </li>
              <li className="nav-item">
                <button
                  className={`nav-link theme_btn ${
                    tabActive.media ? 'active' : ''
                  }`}
                  id="contact-tab"
                  data-toggle="tab"
                  role="tab"
                  aria-controls="media"
                  aria-selected={tabActive.media}
                  onClick={() => handleTabActive('media')}
                >
                  Media
                </button>
              </li>
            </ul>

            {/*     Tabs Contents       */}
            <div className="tab-content" id="myTabContent">
              {/*     Descriptions    */}
              <div
                className={`tab-pane fade ${
                  tabActive.description ? 'show active' : ''
                }`}
                id="description"
                role="tabpanel"
                aria-labelledby="description-tab"
              >
                <section
                  className="project-image-text-area pt-35 pb-60 wow fadeInUp"
                  data-wow-delay=".3s"
                  style={{
                    visibility: 'visible',
                    animationDelay: '0.3s',
                    animationName: 'fadeInUp',
                  }}
                >
                  <div className="container">
                    <div className="row">
                      <div className="col-xl-8 col-lg-7 col-md-12 tri-card-p">
                        <div
                          dangerouslySetInnerHTML={{ __html: cause.content }}
                          className="tri-card-p"
                        ></div>
                      </div>
                      <div className="col-xl-4 col-lg-5 col-md-12">
                        <div className="image-content-right">
                          <div className="widget grey-bg mb-30">
                            <div className="widget-rewards">
                              <h4>Related Cause</h4>
                              {Object.keys(firstLatestCause).length > 0 ? (
                                <>
                                  <div className="reaward__thumb text-center mb-25">
                                    {/*<img*/}
                                    {/*    src={`data:image/png;base64, ${firstLatestCause.image_base64}`}*/}
                                    {/*    alt=""/>*/}
                                    <img
                                      // src={
                                      //   firstLatestCause.image &&
                                      //   process.env
                                      //     .REACT_APP_SERVER_PUBLIC_URL +
                                      //     "/admin/uploads/cause/" +
                                      //     firstLatestCause.image
                                      // }
                                      src={firstLatestCause.image}
                                      alt=""
                                    />
                                  </div>
                                  <h5>{firstLatestCause.title}</h5>
                                  <p className="mb-20 tri-card-p">
                                    {firstLatestCause.short_desc}
                                  </p>

                                  {/* <ul className='rewards-list pt-15 mb-25 p-0 '>
                                    <li className='tri-card-p'>
                                      <i className='fas fa-user-circle' />
                                      <strong>Goals: </strong>$
                                      {firstLatestCause.goal}
                                    </li>
                                    <li className='tri-card-p'>
                                      <i className='fas fa-trophy' />
                                      <strong>Raised: </strong>$
                                      {firstLatestCause.raised}
                                    </li>
                                  </ul> */}
                                  <Link
                                    // className="theme_btn theme_btn_bg tri-card-p"
                                    className="text-uppercase theme_btn_background mx-auto py-3 mx-sm-auto mx-md-0 mx-lg-0 mx-xl-0 theme_btn theme_btn_bg "
                                    style={{
                                      borderWidth: '2px',
                                      fontSize: '12px',
                                    }}
                                    to={`/causes/details/${firstLatestCause.id}`}
                                    data-animation="fadeInLeft"
                                    data-delay=".5s"
                                  >
                                    Donate <i className="fas fa-arrow-right" />
                                  </Link>
                                </>
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                          <div className="widget">
                            <div
                              className="widget-donate-box pos-rel text-center"
                              style={{
                                backgroundImage: `url(${
                                  DonationBg && DonationBg
                                })`,
                              }}
                            >
                              <h5>Donate Now</h5>
                              <h3>Want To Donate</h3>
                              <Link
                                className="theme_btn theme_btn_bg tri-card-p"
                                to="/donations/oneTime"
                                data-animation="fadeInLeft"
                                data-delay=".5s"
                              >
                                donate now <i className="fas fa-arrow-right" />
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>

              {/*     Updates     */}
              <div
                className={`tab-pane fade ${
                  tabActive.updates ? 'show active' : ''
                }`}
                id="updates"
                role="tabpanel"
                aria-labelledby="updates-tab"
              >
                <section className="project-image-text-area pb-90 pt-25">
                  <div className="container">
                    <div className="row">
                      <div className="col-xl-8 col-lg-7 col-md-12">
                        <ul className="timeline mb-100 tri-card-p">
                          {moment(cause.created_at).format('MM/DD/YYYY') !==
                          moment(cause.updated_at).format('MM/DD/YYYY') ? (
                            <li
                              className="timeline-list wow fadeInUp2"
                              data-wow-delay=".1s"
                              style={{
                                visibility: 'visible',
                                animationDelay: '0.1s',
                                animationName: 'fadeInUp2',
                              }}
                            >
                              <div className="update-content">
                                <div className="update-meta">
                                  <span>
                                    <i className="far fa-calendar-alt" />
                                    {moment(cause.updated_at).fromNow(true)}
                                  </span>{' '}
                                  (
                                  <span>
                                    {moment(cause.updated_at).format(
                                      'MM/DD/YYYY'
                                    )}
                                  </span>
                                  )
                                </div>
                                <h4 className="left-line">Causes updated</h4>
                                <p className="tri-card-p">
                                  The cause is updated with some modification
                                </p>
                              </div>
                            </li>
                          ) : (
                            <></>
                          )}
                          <li
                            className="timeline-list wow fadeInUp2 animated animated"
                            data-wow-delay=".1s"
                            style={{
                              visibility: 'visible',
                              animationDelay: '0.1s',
                              animationName: 'fadeInUp2',
                            }}
                          >
                            <div className="update-content">
                              <div className="update-meta tri-card-p">
                                <span>
                                  <i className="far fa-calendar-alt" />
                                  {moment(cause.created_at).fromNow(true)}
                                </span>{' '}
                                (
                                <span>
                                  {moment(cause.created_at).format(
                                    'MM/DD/YYYY'
                                  )}
                                </span>
                                )
                              </div>
                              <h4 className="left-line">Causes Posted</h4>
                              <p className="tri-card-p">
                                This cause is posted by the admin
                              </p>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div className="col-xl-4 col-lg-5 col-md-12">
                        <div className="image-content-right">
                          <div className="widget grey-bg mb-30">
                            <div className="widget-rewards">
                              <h4>Related Cause</h4>
                              {Object.keys(firstLatestCause).length > 0 ? (
                                <>
                                  <div className="reaward__thumb text-center mb-25">
                                    <img
                                      src={
                                        secondLatestCause.image &&
                                        secondLatestCause.image
                                      }
                                      alt=""
                                    />
                                  </div>
                                  <h5>{secondLatestCause.title}</h5>
                                  <p className="mb-20 ">
                                    {secondLatestCause.short_desc}
                                  </p>

                                  {/* <ul className='rewards-list pt-15 mb-25 p-0'>
                                    <li>
                                      <i className='fas fa-user-circle' />
                                      <strong>Goals: </strong>$
                                      {secondLatestCause.goal
                                        ? secondLatestCause.goal
                                        : 0}
                                    </li>
                                    <li>
                                      <i className='fas fa-trophy' />
                                      <strong>Raised: </strong>$
                                      {secondLatestCause.raised
                                        ? secondLatestCause.raised
                                        : 0}
                                    </li>
                                  </ul> */}
                                  <NavLink
                                    className="text-uppercase theme_btn_background mx-auto py-3 mx-sm-auto mx-md-0 mx-lg-0 mx-xl-0 theme_btn theme_btn_bg "
                                    style={{
                                      borderRadius: '20px',
                                      borderWidth: '2px',
                                      fontSize: '12px',
                                    }}
                                    // className="theme_btn theme_btn_bg tri-card-p"
                                    to={`/causes/details/${secondLatestCause.id}`}
                                    data-animation="fadeInLeft"
                                    data-delay=".5s"
                                  >
                                    Donate <i className="fas fa-arrow-right" />
                                  </NavLink>
                                </>
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                          <div className="widget">
                            <div
                              className="widget-donate-box pos-rel text-center"
                              style={{
                                backgroundImage: `url(${DonationBg})`,
                              }}
                            >
                              <h5>Donate Now</h5>
                              <h3>Want To Donate</h3>
                              <Link
                                className="theme_btn theme_btn_bg"
                                to="/donations/oneTime"
                                data-animation="fadeInLeft"
                                data-delay=".5s"
                              >
                                donate now <i className="fas fa-arrow-right" />
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>

              {/*     Donations   */}
              <div
                className={`tab-pane fade ${
                  tabActive.donations ? 'show active' : ''
                }`}
                id="donations"
                role="tabpanel"
                aria-labelledby="donations-tab"
              >
                <small className="text-info tri-card-p">
                  The list of donations are those who are willing to show it in
                  the cause descriptions at the time of donation submission.
                </small>
                <div className="backer-list-table pt-45 pb-130 table-responsive">
                  <table className="table tri-card-p">
                    <thead>
                      <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Donated Amount</th>
                        <th scope="col">Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {causeDonations &&
                        causeDonations.map((donation, index) => (
                          <tr key={index}>
                            <td>
                              {donation.first_name + ' ' + donation.last_name}
                            </td>
                            <td>${donation.amount}</td>
                            <td>
                              {moment(donation.created_at).format('MM/DD/YYYY')}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>

              {/*     Media   */}
              <div
                className={`tab-pane fade ${
                  tabActive.media ? 'show active' : ''
                }`}
                id="media"
                role="tabpanel"
                aria-labelledby="media-tab"
              >
                <div className="mt-4">
                  {Array.isArray(media) && <MediaSliderCause media={media} />}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CauseDetailNavTabs;
