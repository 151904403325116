import React from 'react';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import { Link, useParams } from 'react-router-dom';
import _ from 'lodash';

const PaginationComponent = ({ route, total }) => {
  const params = useParams();

  const currentPage = params.pageNo || '1';

  const isShowPagenation = (i) => {
    // console.log(i, currentPage);

    // const newPage = currentPage + 1;
    const intCurrentPage = Number(currentPage);

    if (i + 1 === intCurrentPage) {
      return true;
    } else if (i >= intCurrentPage && i <= intCurrentPage + 3) {
      return true;
    }
    return false;

    // if (i == currentPage) {
    //   return true;
    // } else if (i == newPage) {
    //   return true;
    // } else {
    //   return false;
    // }
  };

  const isShowPagenationMobile = (i) => {
    // console.log(i, currentPage);

    // const newPage = currentPage + 1;
    const intCurrentPage = Number(currentPage);

    if (i + 1 === intCurrentPage) {
      return true;
    } else if (i >= intCurrentPage && i <= intCurrentPage + 1) {
      return true;
    }
    return false;

    // if (i == currentPage) {
    //   return true;
    // } else if (i == newPage) {
    //   return true;
    // } else {
    //   return false;
    // }
  };

  return (
    <Pagination>
      <PaginationItem className={currentPage === '1' ? 'disable-li' : ''}>
        <Link to={`/${route}/1`} className="text-decoration-none">
          <PaginationLink first />
        </Link>
      </PaginationItem>
      <PaginationItem className={currentPage === '1' ? 'disable-li' : ''}>
        <Link
          to={`/${route}/${parseInt(currentPage) - 1}`}
          className="text-decoration-none"
        >
          <PaginationLink previous />
        </Link>
      </PaginationItem>
      {_.times(Math.ceil(total), (i) =>
        isShowPagenation(i) ? (
          <PaginationItem key={i} className="sm-content-hidden">
            <Link to={`/${route}/${i + 1}`} className="text-decoration-none">
              <PaginationLink
                className={parseInt(currentPage) === i + 1 ? 'active' : ''}
              >
                {i + 1}
              </PaginationLink>
            </Link>
          </PaginationItem>
        ) : (
          <></>
        )
      )}
      {_.times(Math.ceil(total), (i) =>
        isShowPagenationMobile(i) ? (
          <PaginationItem key={i} className="lg-content-hidden">
            <Link to={`/${route}/${i + 1}`} className="text-decoration-none">
              <PaginationLink
                className={parseInt(currentPage) === i + 1 ? 'active' : ''}
              >
                {i + 1}
              </PaginationLink>
            </Link>
          </PaginationItem>
        ) : (
          <></>
        )
      )}
      {/* {_.times(
        Math.ceil(total),
        (i) =>
          i == currentPage && (
            <PaginationItem key={i} className="sm-content-hidden">
              <Link to={`/${route}/${i + 1}`} className="text-decoration-none">
                <PaginationLink
                  className={parseInt(currentPage) === i ? "active" : ""}
                >
                  {i}
                </PaginationLink>
              </Link>
            </PaginationItem>
          )
      )} */}
      {/* {_.times(Math.ceil(total), (i) =>
        isShowPagenation(i) ? (
          <PaginationItem key={i} className="sm-content-hidden">
            <Link to={`/${route}/${i + 1}`} className="text-decoration-none">
              <PaginationLink
                className={parseInt(currentPage) === i + 1 ? "active" : ""}
              >
                {i + 1}
              </PaginationLink>
            </Link>
          </PaginationItem>
        ) : (
          <></>
        )
      )} */}

      {/* {currentPage != Math.ceil(total) - 1 && (
        <div className="mx-1 mx-sm-2 mx-lg-2 mx-md-2 mx-xxl-3 d-flex justify-content-center align-items-end">
          <div
            className=" sm-content-hidden"
            style={{ fontSize: "2rem", color: "#dbdbdb" }}
          >
            <i className="fa-solid fa-ellipsis"></i>
          </div>
        </div>
      )} */}

      {/* {currentPage != Math.ceil(total) - 1 &&
        _.times(Math.ceil(total), (i) =>
          i == Math.ceil(total) - 1 ? (
            <PaginationItem key={i} className="sm-content-hidden">
              <Link to={`/${route}/${i + 1}`} className="text-decoration-none">
                <PaginationLink
                  className={parseInt(currentPage) === i + 1 ? "active" : ""}
                >
                  {i + 1}
                </PaginationLink>
              </Link>
            </PaginationItem>
          ) : (
            <></>
          )
        )} */}
      <PaginationItem
        className={
          parseInt(currentPage) >= Math.ceil(total) ? 'disable-li' : ''
        }
      >
        <Link
          to={`/${route}/${parseInt(currentPage) + 1}`}
          className="text-decoration-none"
        >
          <PaginationLink next />
        </Link>
      </PaginationItem>
      <PaginationItem
        className={
          parseInt(currentPage) >= Math.ceil(total) ? 'disable-li' : ''
        }
      >
        <Link
          to={`/${route}/${Math.ceil(total)}`}
          className="text-decoration-none"
        >
          <PaginationLink last />
        </Link>
      </PaginationItem>
    </Pagination>
  );
};

export default PaginationComponent;
