import {
  DonnationPoster,
  SpecialRequestForm,
  ZakatForm,
} from './components/DonationComponent';
import React, { useEffect, useState } from 'react';
import DeclineInfo from './components/DonationComponent/DeclineInfo';
import CreditCardForm from './components/CreditCardForm';
import DonationService from '../../services/donation.service';
import EmployeeMatchingGiftProgram from './components/EmployeeMatchingGiftProgram';
import ErrorFormat from '../../utils/ErrorFormatting';
import PersonalInfoForm from './components/PersonalInfoForm';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { wait } from '../../utils/constants';
import EcheckRadioButtons from '../../components/EcheckRadioButtons';
import ACHPaymentForm from './components/ACHPaymentForm';
import PlaidLink from '../../components/CreatePlaidLink';

const ZakatDonation = () => {
  const navigate = useNavigate();
  const [specialRequest, setSpecialRequest] = useState([]);
  const [specialRequestBackup, setSpecialRequestBackup] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [total, setTotal] = useState(0);
  const [totalDonation, setTotalDonation] = useState([]);
  const [personalInfo, setPersonalInfo] = useState({});
  const [creditCardInfo, setCreditCardInfo] = useState({});
  const [finalDonationData, setFinalDonationData] = useState({});
  const [zakaDonation, setZakaDonation] = useState({});
  const [zakatNissab, setZakatNissab] = useState({});
  const [fitraNissab, setFitraNissab] = useState({});
  const [disableSubmitButton, setDisableSubmitButton] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [currency] = useState('usd');
  const [submissionState, setSubmissionState] = useState(0);
  const [isResetForm, setIsResetForm] = useState(false);
  const [employeeMatchingGiftProgram, setEmployeeMatchingGiftProgram] =
    useState({});

  const handleSum = (sum) => {
    setTotalAmount(sum);
  };
  const [isEcheck, setIsEcheck] = useState(false);
  const [achInfo, setAchInfo] = useState({});
  const [errors, setErrors] = useState({});

  const [stripeToken, setStripeToken] = useState(null);
  const handleStripeTokenReceived = (stripeToken) => {
    setStripeToken(stripeToken);
    // console.log({ stripeToken });
  };

  const getZakatNissab = () => {
    DonationService.getZakatNissab().then((res) => {
      setZakatNissab(res);
    });
  };

  const getFitraNissab = () => {
    DonationService.getFitraNissab().then((res) => {
      setFitraNissab(res);
    });
  };

  const getDonationType = () => {
    DonationService.getSpecialDonationTypes().then((res) => {
      setSpecialRequest(res);
      setSpecialRequestBackup(res);
    });
    /*  DonationService.donationType().then((res) => {
      setSpecialRequest(res.filter((item) => item.is_special !== 0));
    }); */
  };
  // console.log("zakat donation", finalDonationData);

  const scrollTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };

  const extendSubmissionState = () => {
    setSubmissionState((state) => state + 1);
  };

  const startSubmit = () => {
    setSubmissionState(1);
    setTimeout(() => {
      setSubmissionState(0);
    }, 1000);
  };

  useEffect(() => {
    const totalForms = 1;
    if (submissionState >= totalForms + 1) {
      if (isEcheck) {
        if (stripeToken) {
          handleSubmit();
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'You did not connect a bank account!',
          });
        }
      } else {
        handleSubmit();
      }
    }
  }, [submissionState]);

  const checkIsZakat = () => {
    {
      startSubmit();
    }
  };

  const handleSubmit = () => {
    setIsLoading(true);
    if (
      !zakaDonation?.fitra?.fitra_amount &&
      !zakaDonation?.zakat?.zakat_paid
    ) {
      setIsLoading(false);

      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Please select some amount to give zakat or some fitra!',
      });
      return;
    } else {
      DonationService.submitDonation({
        matchingGift: employeeMatchingGiftProgram,
        personal: personalInfo,
        card: creditCardInfo,
        ach: { stripeToken },
        isEcheck,
        special: totalDonation,
        donation_of: {
          oneTime: false,
          monthly: false,
          zakat_fitra_donation: true,
          qurbani: false,
          cause: false,
          special: totalDonation.length > 0,
        },
        zakat_fitra: zakaDonation,
        payment_through: isEcheck ? 'ach' : 'card',
        currency: currency,
        duration_id: 1,
      })
        .then((data) => {
          if (data.payment.status && data.email.status) {
            let errorExists;
            if (!data.donor.status) errorExists = true;
            else if (!data.cc_details.status) errorExists = true;
            else errorExists = !data.zakat_fitra.status;

            Swal.fire({
              title: 'Thank you for your donation!',
              text: `${
                errorExists
                  ? 'Some information cannot saved successfully but donation done successfully, if it concern do contact Ummah Relief. A verification email has been send to you with details'
                  : 'A confirmation email has been sent to the email provided.'
              }`,
              icon: 'success',
              allowOutsideClick: false,
              allowEscapeKey: false,
            }).then((r) => window.location.reload());
          } else {
            let errorMessages;
            setIsLoading(false);
            if (!data.payment.status) errorMessages = data.payment.message;
            else if (!data.email.status) errorMessages = data.email.message;

            Swal.fire({
              icon: 'error',
              title: 'Error occurred',
              html: ErrorFormat.validationErrors(errorMessages),
            });
          }

          // if (data.payment.status && data.email.status && data.donor.status && data.cc_details.status && data.zakat_fitra.status) {
          //     Swal.fire(
          //         'Thanks for helping!',
          //         'An email has been sent to you, thanks for your help by helping humanity!',
          //         'success'
          //     ).then(r => window.location.reload())
          // } else {
          //     let errorMessages;
          //     if (!data.payment.status)
          //         errorMessages = data.payment.message
          //     else if(!data.email.status)
          //         errorMessages = data.email.message
          //     Swal.fire({
          //         icon: 'error',
          //         title: 'Error occurred',
          //         html: ErrorFormat.validationErrors(errorMessages),
          //     })
          // }
        })
        .catch((err) => {
          setIsLoading(false);
          // console.log(err.response.data.message)
          if (err.response.data.message) {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: err.response.data.message,
            });
          }
        });
    }
  };

  useEffect(() => {
    document.title = 'Zakat Donations | Ummah Relief';
    getDonationType();
    getZakatNissab();
    getFitraNissab();
    scrollTop();
  }, []);

  useEffect(() => {
    setFinalDonationData({
      matchingGift: employeeMatchingGiftProgram,
      personal: personalInfo,
      card: creditCardInfo,
      ach: { stripeToken },
      isEcheck,
      special: totalDonation,
      donation_of: {
        oneTime: false,
        monthly: false,
        zakat_fitra_donation: true,
        qurbani: false,
        cause: false,
        special: totalDonation.length > 0,
      },
      zakat_fitra: zakaDonation,
      payment_through: isEcheck ? 'ach' : 'card',
      currency: currency,
      duration_id: 1,
    });
    const a = parseFloat(zakaDonation?.fitra?.fitra_amount || 0);
    const b = parseFloat(zakaDonation?.zakat?.zakat_paid || 0);
    setTotal(totalAmount + a + b);
    if (
      isValidated(personalInfo) &&
      isValidated(creditCardInfo) &&
      isValidated(zakaDonation)
    ) {
      setDisableSubmitButton(false);
    } else {
      setDisableSubmitButton(true);
    }
    // console.log(finalDonationData)
  }, [
    totalAmount,
    zakaDonation,
    totalDonation,
    personalInfo,
    creditCardInfo,
    achInfo,
    currency,
    employeeMatchingGiftProgram,
  ]);

  const isValidated = (values) => {
    if (typeof values === 'object') {
      return Object.values(values).every(
        (x) => x !== null || x !== '' || x !== 0
      );
    } else {
      return values !== '' || values !== null;
    }
  };

  const resetForm = async () => {
    setIsResetForm(true);
    setTotalAmount(0);
    setTotalDonation([]);
    setPersonalInfo({});
    setCreditCardInfo({});
    setFinalDonationData({});
    setAchInfo({});
    setIsEcheck(false);
    setSpecialRequest(specialRequestBackup);
    await wait(1);
    setIsResetForm(false);
  };

  return (
    <div className="row">
      <div className="col-xl-8 col-lg-7 col-md-12">
        {!isResetForm && (
          <SpecialRequestForm
            options={specialRequest}
            setOptions={setSpecialRequest}
            handleSum={(sum) => handleSum(sum)}
            setTotalDonation={(total) => setTotalDonation(total)}
            totalDonation={totalDonation}
            totalAmount={totalAmount}
          />
        )}
        {!isResetForm && (
          <ZakatForm
            zakaDonation={zakaDonation}
            setZakaDonation={(donation) => setZakaDonation(donation)}
            zakatNissab={zakatNissab}
            fitraNissab={fitraNissab}
          />
        )}
      </div>
      <div className="col-xl-4 col-lg-4 col-md-12 pt-5 pt-lg-0 pt-xl-0 pt-xxl-0">
        {!isResetForm && (
          <PersonalInfoForm
            submissionState={submissionState}
            extendSubmissionState={extendSubmissionState}
            personalInfo={personalInfo}
            setPersonalInfo={(info) => setPersonalInfo(info)}
          />
        )}
        {!isResetForm && (
          <EmployeeMatchingGiftProgram
            setEmployeeMatchingGiftProgram={(info) =>
              setEmployeeMatchingGiftProgram(info)
            }
          />
        )}
        <EcheckRadioButtons
          isEcheck={isEcheck}
          setIsEcheck={(echeck) => setIsEcheck(echeck)}
        />
        {!isResetForm && !isEcheck && (
          <CreditCardForm
            submissionState={submissionState}
            extendSubmissionState={extendSubmissionState}
            creditCardInfo={creditCardInfo}
            setCreditCardInfo={(creditCard) => setCreditCardInfo(creditCard)}
          />
        )}
        {!isResetForm && isEcheck && (
          <PlaidLink onStripeTokenReceived={handleStripeTokenReceived} />
          // <ACHPaymentForm
          //   submissionState={submissionState}
          //   extendSubmissionState={extendSubmissionState}
          //   achInfo={achInfo}
          //   setAchInfo={(ach) => setAchInfo(ach)}
          //   errors={errors}
          // />
        )}
        <div className="d-flex justify-content-start flex-wrap align-items-center gap-3">
          <button
            className="text-uppercase btn-green border-0 py-1 px-3"
            style={{
              borderRadius: '20px',
              borderWidth: '2px',
              fontSize: '14px',
            }}
            disabled={isLoading}
            onClick={checkIsZakat}
            type="button"
          >
            {isLoading ? (
              <>
                Proccessing{' '}
                <span
                  className="spinner-border"
                  role="status"
                  style={{
                    height: '14px',
                    width: '14px',
                  }}
                ></span>
              </>
            ) : (
              'Submit'
            )}
          </button>
          <button
            onClick={resetForm}
            className="text-uppercase btn-orang border-0 py-1 px-3"
            style={{
              borderRadius: '20px',
              borderWidth: '2px',
              fontSize: '14px',
            }}
            type="button"
            disabled={isLoading}
          >
            Reset
          </button>
          <button
            type="button"
            className="text-uppercase btn-gray text-white border-0 py-1 px-3"
            style={{
              borderRadius: '20px',
              borderWidth: '2px',
              fontSize: '14px',
            }}
            onClick={() =>
              navigate(`/`, {
                replace: true,
              })
            }
            disabled={isLoading}
          >
            Cancel
          </button>
        </div>
        {/* <div className='d-flex justify-content-end gap-3'>
          <button
            className='text-uppercase btn btn-outline-light text-white bg-success text-success fw-bold px-4 tri-card-p text-white mx-auto mx-sm-auto mx-md-0 mx-lg-0 mx-xl-0 theme_btn theme_btn_bg '
            style={{
              borderRadius: '20px',
              borderWidth: '2px',
              fontSize: '12px',
            }}
            // className="btn btn-success btn-lg"
            type='button'
            disabled={isLoading}
            onClick={() => startSubmit()}
          >
            {isLoading ? 'Processing...' : 'Donate Now'}
          </button>
        </div> */}
        <DeclineInfo type={'Zakat'} />
        <DonnationPoster />
      </div>
    </div>
  );
};

export default ZakatDonation;
