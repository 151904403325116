import React from 'react';
import { Helmet } from 'react-helmet';

function NoIndexNoFollow() {
  return (
    <Helmet>
      <meta name="robots" content="noindex, nofollow" />
    </Helmet>
  );
}

export default NoIndexNoFollow;
