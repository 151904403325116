import React, {useEffect, useState} from 'react';
import {LogoLoader} from "../../../../utils/Images";
import BlogCard from "./BlogCard";
import {PaginationComponent} from "../../../../components";
import {useParams} from "react-router-dom";
import BlogService from "../../../../services/blogs.service";

const SearchBlogList = ({blogData}) => {
    const params = useParams()
    const [blogs, setBlogs] = useState([])
    const [from, setFrom] = useState(0)
    const [to, setTo] = useState(0)
    const [total, setTotal] = useState(0)
    const [perPage, setPerPage] = useState(0)

    useEffect(() => {
        if (Object.keys(blogData).length > 0) {
            setBlogs(blogData.data)
            setFrom(blogData.from)
            setTo(blogData.to)
            setTotal(blogData.total)
            setPerPage(blogData.per_page)
            // console.log(blogData, blogs)
        } else {
            setBlogs([])
            setFrom(0)
            setTo(0)
            setTotal(0)
            setPerPage(0)
        }
    }, [blogData])

    return (
        <div>
            {!from
                ? <h3>No blogs found</h3>
                : blogs.length === 0
                    ? <div className="d-flex flex-column justify-content-center align-items-center">
                        <img src={LogoLoader} alt=""/>
                        <h3>Loading...</h3>
                    </div>
                    : <>
                        <div className="row">
                            {blogs.map((blog, index) => (
                                <BlogCard blog={blog} key={index}/>
                            ))}
                            {/*<div*/}
                            {/*    className="d-flex flex-column flex-sm-row flex-md-row flex-lg-row justify-content-between align-items-center">*/}
                            {/*    <span>Showing <strong>{from}</strong> to <strong>{to}</strong> of <strong>{total}</strong> entries</span>*/}
                            {/*    <PaginationComponent route={`search/${params.searchBy}`} total={total / perPage}/>*/}
                            {/*</div>*/}
                        </div>
                    </>}
        </div>
    );
};

export default SearchBlogList;