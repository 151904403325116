import React, { useEffect } from "react";
import { PageHeader } from "../../components";
import { BlogList } from "./components/BlogsComponent";

const breadcrumbItems = [
  {
    title: "Home",
    route: "/",
    isLast: false,
  },
  {
    title: "Blogs",
    route: `/blogs/1`,
    isLast: true,
  },
];

const Blogs = () => {
  const scrollTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };
  useEffect(() => {
    document.title = "Blogs | Ummah Relief";
    scrollTop();
  }, []);
  return (
    <>
      <PageHeader breadcrumbItems={breadcrumbItems} title="All Blogs" />
      <BlogList />
    </>
  );
};

export default Blogs;
