import React, { useContext, useEffect, useState } from 'react';

import WebService from '../../../../services/web.service';
import { AppContext } from '../../../../layouts/WebsiteLayout';

const ContactDetailComponent = () => {
  const [location, setLocation] = useState('');
  const [postalAddress, setPostalAddress] = useState('');
  const [siteEmail, setSiteEmail] = useState('');
  const [phoneNumbers, setPhoneNumbers] = useState();
  const [mobileNumber, setMobileNumner] = useState('');
  const [uanNumber, setUanNumber] = useState('');
  const { websiteSettings } = useContext(AppContext);

  const getWebSettings = (res) => {
    const webInfo = res.web_info;
    for (let i = 0; i < webInfo.length; i++) {
      if (webInfo[i].setting === 'phoneNumber') {
        setPhoneNumbers(
          webInfo[i].value.replace(/([0-9]{3})([0-9]{3})([0-9]+)/, '$1-$2-$3')
        );
      }
      if (webInfo[i].setting === 'mobile') {
        var ph = webInfo[i].value ? webInfo[i].value.replaceAll('-', '') : '';
        var formatedNumber = ph.replace(
          /([0-9]{3})([0-9]{3})([0-9]+)/,
          '$1-$2-$3'
        );
        setMobileNumner(formatedNumber);
      }
      if (webInfo[i].setting === 'uan') {
        var ph = webInfo[i].value ? webInfo[i].value.replaceAll('-', '') : '';
        // console.log(ph);
        var formatedNumber = ph.replace(
          /([0-9]{1})([0-9]{3})([0-9]{3})([0-9]+)/,
          '$1-$2-$3-$4'
        );
        setUanNumber(formatedNumber);
      }
      if (webInfo[i].setting === 'address') {
        setLocation(webInfo[i].value);
      }
      if (webInfo[i].setting === 'postalAddress') {
        setPostalAddress(webInfo[i].value);
      }
      if (webInfo[i].setting === 'emailAddress') {
        setSiteEmail(webInfo[i].value);
      }
    }
  };

  useEffect(() => {
    websiteSettings && getWebSettings(websiteSettings);
  }, [websiteSettings]);

  return (
    <section className="contact-box-area sm-pb-2 sm-pt-2 pb-40 pt-5">
      <div className="container">
        <div className="row ">
          <div className="col-xl-10 offset-xl-1">
            <div
              className="section-title text-center sm-mb-0 md-mb-0  mb-85 wow fadeInUp2  animated"
              data-wow-delay=".1s"
              style={{
                visibility: 'visible',
                animationDelay: '0.1s',
                animationName: 'fadeInUp2',
              }}
            >
              <h6 className="left-line pl-75 pr-75">Contact Us</h6>
              <h5 className="text-secondary">
                Call now to join our mission. Together We are Ummah.
              </h5>
              <h2 className="contact-title">
                We’ve Taken A Mission to <br />
                <span>Save Humanity</span>
              </h2>
            </div>
          </div>
        </div>
        <div className="row ">
          <div className="col-xl-4 col-lg-4 col-md-8 col-12 text-center text-md-center text-lg-start text-xl-start md-box-center ">
            <div
              className="contact-box d-cbox-flex  sm-mb-0 md-mb-0 mb-30 wow fadeInUp2 animated"
              data-wow-delay=".1s"
              style={{
                visibility: 'visible',
                animationDelay: '0.1s',
                animationName: 'fadeInUp2',
              }}
            >
              <div className="contact-box__icon">
                <i className="fas fa-map-marker-alt" />
              </div>
              <div className="contact-box__content">
                <h4>Locations</h4>
                {location && (
                  <h5
                    dangerouslySetInnerHTML={{
                      __html:
                        location ||
                        '339 W River Rd, Elgin, IL 60123,<br>United States',
                    }}
                  />
                )}
                <br />
                {postalAddress && (
                  <h5
                    dangerouslySetInnerHTML={{
                      __html:
                        postalAddress ||
                        'P.O. Box 1426, Elgin, IL 60121,<br>United States',
                    }}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-lg-4 col-md-8 col-12 text-center text-md-center text-lg-start text-xl-start md-box-center">
            <div
              className="contact-box d-cbox-flex sm-mb-0 mb-30 wow fadeInUp2  animated"
              data-wow-delay=".3s"
              style={{
                visibility: 'visible',
                animationDelay: '0.3s',
                animationName: 'fadeInUp2',
              }}
            >
              <div className="contact-box__icon">
                <i className="fas fa-envelope" />
              </div>
              <div className="contact-box__content">
                <h4>Email Us</h4>
                <h5>
                  {siteEmail && (
                    <a
                      className="text-decoration-none"
                      href={`mailto:${siteEmail || 'Ummah@ummahrelief.org'}`}
                    >
                      {siteEmail || 'Ummah@ummahrelief.org'}
                    </a>
                  )}
                </h5>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-lg-4 col-md-8 col-12 text-center text-md-center text-lg-start text-xl-start md-box-center">
            <div
              className="contact-box d-cbox-flex sm-mb-0 mb-30 wow fadeInUp2  animated"
              data-wow-delay=".5s"
              style={{
                visibility: 'visible',
                animationDelay: '0.5s',
                animationName: 'fadeInUp2',
              }}
            >
              <div className="contact-box__icon">
                <i className="fas fa-phone" />
              </div>
              <div className="contact-box__content">
                <h4>Phone Us</h4>
                {uanNumber && (
                  <h5 className="d-flex gap-2 align-items-center">
                    <i className="fas fa-phone"></i>

                    <a
                      className="text-decoration-none"
                      href={`tel: ${uanNumber}`}
                    >
                      {uanNumber}
                    </a>
                  </h5>
                )}

                {mobileNumber && (
                  <h5 className="d-flex gap-2 align-items-center">
                    <i className="fas fa-mobile"></i>
                    <a
                      className="text-decoration-none"
                      href={`tel: ${mobileNumber}`}
                    >
                      {mobileNumber}
                    </a>
                  </h5>
                )}

                {phoneNumbers && (
                  <h5 className="d-flex gap-2 align-items-center">
                    <i className="fas fa-fax"></i>
                    <a
                      className="text-decoration-none"
                      href={`tel: ${phoneNumbers || '847-622-0574'}`}
                    >
                      {phoneNumbers || '847-622-0574'}
                    </a>

                    {/* {phoneNumbers && (
                    <span key={phoneNumbers}>{phoneNumbers}</span>
                  )} */}
                  </h5>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactDetailComponent;

/* 

<li className='nav-item mb-2 d-flex gap-3 justify-content-start align-items-center'>
                  <div className='col-1 text-center'>
                    <i className='fas fa-phone'></i>
                  </div>
                  <div className='col-11'>
                    <a className='text-decoration-none text-white'
                      href={`tel: ${uanNumber || "1-800-713-4482"}`}>{uanNumber || "1-800-713-4482"}</a>
                  </div>
                </li>
                <li className='nav-item mb-2 d-flex gap-3 justify-content-start align-items-center'>
                  <div className='col-1 text-center'>
                    <i className='fas fa-mobile'></i>
                  </div>
                  <div className='col-11'>
                    <a className='text-decoration-none text-white'
                      href={`tel: ${mobileNumber || "847-622-0574"}`}>{mobileNumber || "847-622-0574"}</a>
                  </div>
                </li>
                <li className='nav-item mb-2 d-flex gap-3 justify-content-start align-items-center'>
                  <div className='col-1 text-center'>
                    <i className='fas fa-fax'></i>
                  </div>
                  <div className='col-11'>
                    <a className='text-decoration-none text-white'
                      href={`tel: ${phoneNumber || "847-741-3816"}`}>{phoneNumber || "847-741-3816"}</a>
                  </div>
                </li>

*/
