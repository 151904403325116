import API from "../utils/api";

const getCountries = () => {
    return API.get('countries')
        .then(response => {
            return response.data
        })
}

const getStates = (country_id) => {
    return API.get(`/country/${country_id}/states`)
        .then(response => {
            return response.data
        })
}

const getCities = (country_id, state_id) => {
    return API.get(`/cities/${country_id}/${state_id}`)
        .then(response => {
            return response.data
        })
    // return API.get(`/cities/${state_id}`)
    // .then(response => {
    //     return response.data
    // })
}

const LocationService = {
    getCountries,
    getStates,
    getCities
};

export default LocationService;