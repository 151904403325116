const email = (email, id) => {
  if (!email) {
    document.getElementById(id).style.borderColor = "red";
    return "Email is required";
  } else if (!new RegExp(/\S+@\S+\.\S+/).test(email)) {
    document.getElementById(id).style.borderColor = "red";
    return "Incorrect email format";
  }
  document.getElementById(id).style.borderColor = "#ced4da";
  return "";
};

const password = (password, id) => {
  if (!password) {
    document.getElementById(id).style.borderColor = "red";
    return "Password is required";
  } else if (password.length < 8) {
    document.getElementById(id).style.borderColor = "red";
    return "Password must have a minimum 8 characters";
  }
  document.getElementById(id).style.borderColor = "#ced4da";
  return "";
};

const creditCardNumber = (cardNo, id, brandName) => {
  // console.log("Brand Name ", brandName);
  if (!cardNo) {
    document.getElementById(id).style.borderColor = "red";
    return "Card number is required";
  } else if (
    (brandName !== "american-express" && cardNo.length !== 19) ||
    (brandName == "american-express" && cardNo.length !== 18)
  ) {
    document.getElementById(id).style.borderColor = "red";
    return "Card number is not valid";
  }
  document.getElementById(id).style.borderColor = "#ced4da";
  return "";
};

const expiryYear = (val, id) => {
  if (!val) {
    document.getElementById(id).style.borderColor = "red";
    return "Field is required";
  } else if (val.length !== 4) {
    document.getElementById(id).style.borderColor = "red";
    return "Expiry year is incorrect";
  }
  document.getElementById(id).style.borderColor = "#ced4da";
  return "";
};

const cardVerificationCode = (val, id, brandName) => {
  if (!val) {
    document.getElementById(id).style.borderColor = "red";
    return "Field is required";
  }
  // else if ((brandName !== "american-express" && val.length < 3) || (brandName == "american-express" && val.length < 4)) {
  else if (val.length < 3) {
    document.getElementById(id).style.borderColor = "red";
    return "CVC is incorrect";
  }
  document.getElementById(id).style.borderColor = "#ced4da";
  return "";
};

const isEmpty = (val, id) => {
  if (!val) {
    document.getElementById(id).style.borderColor = "red";
    return "Field is required";
  }
  var element = document.getElementById(id);
  document.getElementById(id).style.borderColor = "#ced4da";
  return "";
};

const numberMaxCheck = (min, max, val) => Math.max(min, Math.min(val, max));

const maxLengthCheck = (value, maxValue) => {
  if (value.length > maxValue) {
    return `Please enter at least ${maxValue} characters!`;
  }
  return "";
};

const minLengthCheck = (value, minValue) => {
  if (value.length < minValue) {
    return `Please enter at least ${minValue} characters!`;
  }
  return "";
};

const isText = (val, id) => {
  // const text = /^[a-zA-Z][a-zA-Z\\s]+$/;
  const text = /^[a-zA-Z ]*$/;
  if (!text.test(val)) {
    document.getElementById(id).style.borderColor = "#dc3545";
    return "Please enter alphabets only!";
  }
  document.getElementById(id).style.borderColor = "#ced4da";
  return "";
};

const isValidZipCode = (val, id) => {
  // const zipCode = /^[a-z0-9][a-z0-9\-]{0,10}[a-z0-9]$/;
  // const zipCode = /^[a-z0-9][a-z0-9\- ]{0,10}[a-z0-9]$/;
  // const zipCode =
  // /[ABCEGHJ-NPRSTVXY]{1}[0-9]{1}[ABCEGHJ-NPRSTV-Z]{1}[ ]?[0-9]{1}[ABCEGHJ-NPRSTV-Z]{1}[0-9]{1}$/;
  const zipCode = /^\d+$/;
  if (zipCode.test(val)) {
    document.getElementById(id).style.borderColor = "#dc3545";
    return "Please enter a valid zipcode!";
  }
  document.getElementById(id).style.borderColor = "#ced4da";
  return "";
};

const handleOnBlur = (val, setErrors) => {
  // console.log(val.target.id)
  // console.log({ v: val.target.value, i: val.target.id });
  let message = "";
  if (val.target.id === "card_number") {
    message = creditCardNumber(
      val.target.value,
      val.target.id,
      val.target.name
    );
    setErrors((old) => {
      return { ...old, cardNoError: message };
    });
  } else if (val.target.id === "name_on_card") {
    message = isEmpty(val.target.value, val.target.id);
    setErrors((old) => {
      return { ...old, cardName: message };
    });
    if (message === "") {
      message = isText(val.target.value, val.target.id);
      setErrors((old) => {
        return { ...old, cardName: message };
      });
    }
  } else if (val.target.id === "exp_year") {
    message = expiryYear(val.target.value, val.target.id);

    if (message === "") {
      const currentYear = new Date().getFullYear();
      if (val.target.value < currentYear) {
        message = "Credit Card is expired";
      }
    }
    setErrors((old) => {
      return { ...old, expiryYear: message };
    });
  } else if (val.target.id === "exp_month") {
    message = isEmpty(val.target.value, val.target.id);
    setErrors((old) => {
      return { ...old, exp_month: message };
    });
  } else if (val.target.id === "csc_code") {
    message = cardVerificationCode(
      val.target.value,
      val.target.id,
      val.target.name
    );
    setErrors((old) => {
      return { ...old, cvc: message };
    });
  } else if (val.target.id === "name") {
    message = isEmpty(val.target.value, val.target.id);

    if (message === "") {
      message = isText(val.target.value, val.target.id);
    }
    setErrors((old) => {
      return { ...old, name: message };
    });
  } else if (val.target.id === "first_name") {
    message = isEmpty(val.target.value, val.target.id);
    setErrors((old) => {
      return { ...old, firstName: message };
    });

    if (message === "") {
      message = isText(val.target.value, val.target.id);
      setErrors((old) => {
        return { ...old, firstName: message };
      });
    }
  } else if (val.target.id === "last_name") {
    message = isEmpty(val.target.value, val.target.id);
    setErrors((old) => {
      return { ...old, lastName: message };
    });
    if (message === "") {
      message = isText(val.target.value, val.target.id);
      setErrors((old) => {
        return { ...old, lastName: message };
      });
    }
  } else if (val.target.id === "address") {
    message = isEmpty(val.target.value, val.target.id);
    setErrors((old) => {
      return { ...old, address: message };
    });
  } else if (val.target.id === "zip_code") {
    message = isEmpty(val.target.value, val.target.id);
    // if (message === "") {
    //   message = minLengthCheck(val.target.value, 2);
    //   if (message === "") {
    //     message = maxLengthCheck(val.target.value, 12);
    //     if (message === "") {
    //       message = isValidZipCode(val.target.value, val.target.id);
    //     }
    //   }
    // }
    setErrors((old) => {
      return { ...old, zipCode: message };
    });
  } else if (val.target.id === "email") {
    message = email(val.target.value, val.target.id);
    setErrors((old) => {
      return { ...old, email: message };
    });
  } else if (val.target.id === "phone") {
    message = isEmpty(val.target.value, val.target.id);

    if (message !== "")
      setErrors((old) => {
        return { ...old, phone: message };
      });
    else if (val.target.value.toString().trim().length < 7) {
      setErrors((old) => {
        document.getElementById(val.target.id).style.borderColor = "red";
        return { ...old, phone: "Phone number should be at least 7 digits" };
      });
    }
    setErrors((old) => {
      return { ...old, phone: message };
    });
  } else if (val.target.id === "subject") {
    message = isEmpty(val.target.value, val.target.id);
    if (message === "") {
      message = isText(val.target.value, val.target.id);
    }
    setErrors((old) => {
      return { ...old, subject: message };
    });
  } else if (val.target.id === "text_message") {
    message = isEmpty(val.target.value, val.target.id);

    setErrors((old) => {
      return { ...old, text_message: message };
    });
  } else if (val.target.id === "country") {
    message = isEmpty(val.target.value, val.target.id);
    setErrors((old) => {
      return { ...old, country: message };
    });
  } else if (val.target.id === "state") {
    message = isEmpty(val.target.value, val.target.id);
    setErrors((old) => {
      return { ...old, state: message };
    });
  } else if (val.target.id === "city") {
    message = isEmpty(val.target.value, val.target.id);
    setErrors((old) => {
      return { ...old, city: message };
    });
  } else if (val.target.id === "account_holder_name") {
    message = isEmpty(val.target.value, val.target.id);
    setErrors((old) => {
      return { ...old, account_holder_name: message };
    });
  } else if (val.target.id === "routing_number") {
    message = isEmpty(val.target.value, val.target.id);
    setErrors((old) => {
      return { ...old, routing_number: message };
    });
  } else if (val.target.id === "account_number") {
    message = isEmpty(val.target.value, val.target.id);
    setErrors((old) => {
      return { ...old, account_number: message };
    });
  }
  // console.log({ message });
  return message === "";
};

const handleCreditCard = (val, setErrors, id) => {
  let message = "";
  if (val.target.id === "exp_year") {
    message = expiryYear(val.target.value, val.target.id);

    if (message === "") {
      const currentYear = new Date().getFullYear();
      if (val.target.value < currentYear) {
        message = "Credit Card is expired";
      }
    }
    setErrors((old) => {
      return { ...old, expiryYear: message };
    });
  } else if (val === "exp_month") {
    message = isEmpty(val, id);
    setErrors((old) => {
      return { ...old, exp_month: message };
    });
  }
};

const requiredFieldCheck = (object, donationType) => {
  let validationArray = [];
  Object.entries(object).forEach(([key, value]) => {
    if (donationType === "oneTime" || donationType === "monthly") {
      if (key !== "qurbanies" && key !== "zakat" && key !== "cause") {
        if (!value || value === "" || value === null || value?.length === 0) {
          validationArray.push(false);
        } else {
          validationArray.push(true);
        }
      }
    } else if (donationType === "qurbani") {
      if (key !== "donations" && key !== "zakat" && key !== "cause") {
        if (!value || value === "" || value === null || value?.length === 0) {
          validationArray.push(false);
        } else {
          validationArray.push(true);
        }
      }
    } else if (donationType === "zakat") {
      if (key !== "donations" && key !== "qurbani" && key !== "cause") {
        if (!value || value === "" || value === null || value?.length === 0) {
          validationArray.push(false);
        } else {
          validationArray.push(true);
        }
      }
    } else if (donationType === "cause") {
      if (key !== "donations" && key !== "qurbani" && key !== "zakat") {
        if (!value || value === "" || value === null || value?.length === 0) {
          validationArray.push(false);
        } else {
          validationArray.push(true);
        }
      }
    }
  });
  return validationArray;
};

const isValidRoutingNumber = (number) => {
  var valid = require("us-bank-account-validator");

  var routingValidation = valid.routingNumber(number);
  // console.log({ routingValidation });
  return routingValidation.isValid;
};
const isValidAccountNumber = (number) => {
  var valid = require("us-bank-account-validator");

  var routingValidation = valid.accountNumber(number);
  // console.log({ routingValidation });
  return routingValidation.isValid;
};
const FormValidator = {
  email,
  password,
  creditCardNumber,
  numberMaxCheck,
  maxLengthCheck,
  handleOnBlur,
  isEmpty,
  requiredFieldCheck,
  isValidZipCode,
  handleCreditCard,
  isValidRoutingNumber,
  isValidAccountNumber,
};

export default FormValidator;
