import React, {useState, useEffect} from 'react';
import {useParams} from "react-router-dom";
import CauseService from "../../../../services/causes.service";
import {LogoLoader} from "../../../../utils/Images";
import CauseCard from "./CauseCard";
import {PaginationComponent} from "../../../../components";

const SearchCauseList = ({causeData}) => {

    const [causes, setCauses] = useState([])
    const [from, setFrom] = useState(0)
    const [to, setTo] = useState(0)
    const [total, setTotal] = useState(0)
    const [perPage, setPerPage] = useState(0)
    // console.log(causeData)
    useEffect(() => {
        if (Object.keys(causeData).length > 0) {
            setCauses(causeData.data)
            setFrom(causeData.from)
            setTo(causeData.to)
            setTotal(causeData.total)
            setPerPage(causeData.per_page)
        } else {
            setCauses([])
            setFrom(0)
            setTo(0)
            setTotal(0)
            setPerPage(0)
        }
    }, [causeData])

    return (
        <div className="my-1">
            {!from
                ? <h3>No cause found</h3>
                : causes.length === 0
                    ? <div className="d-flex flex-column justify-content-center align-items-center">
                        <img src={LogoLoader} alt=""/>
                        <h3>Loading...</h3>
                    </div>
                    : <>
                        <div className="causes-wrap row">
                            {causes && causes.map((cause, index) => {
                                return (
                                    <CauseCard cause={cause} key={index}/>
                                )
                            })}

                        </div>
                        {/*<div*/}
                        {/*    className="d-flex flex-column flex-sm-row flex-md-row flex-lg-row justify-content-between align-items-center">*/}
                        {/*    <span>Showing <strong>{from}</strong> to <strong>{to}</strong> of <strong>{total}</strong> entries</span>*/}
                        {/*    <PaginationComponent route="causes" total={total / perPage}/>*/}
                        {/*</div>*/}
                    </>}
        </div>
    );
};

export default SearchCauseList;